<template>
    <div class="chatItem">
        <div class="chatAvatar" :class="chatRecord.sendId==store.introInfo.id?'me':'other'">
            <img :src="chatRecord.sendId==store.introInfo.id?store.introInfo.avatar:chatAvatar" alt="">
        </div>
        <div class="chatFrame" v-if="chatRecord.type==0" :class="chatRecord.sendId==store.introInfo.id?'me':'other'">{{chatRecord.content}}</div>
        <div class="chatFrame" v-else :class="chatRecord.sendId==store.introInfo.id?'me':'other'">
        
             <div class="blog-card">
                <!-- 用户信息区域 -->
                <div class="user-info">
                <div class="user-basic">
                    <el-avatar :size="40" :src="blogItem.avatar" />
                    <div class="user-detail">
                    <div class="nickname">{{ blogItem.nickname }}</div>
                    <div class="publish-info">
                        <span>{{ blogItem.publishTime }}</span>
                        <span class="dot">·</span>
                        <span>{{ blogItem.publishIp }}</span>
                    </div>
                    </div>
                </div>
                <el-button @click="router.push(`/personpg/${blogItem.userId}`)">主页</el-button>
                </div>

                <!-- 博客内容区域 -->
                <div class="blog-content">
                <h3 class="blog-title">{{ blogItem.title }}</h3>
                <p class="blog-text">{{ blogItem.content }}</p>
                
                <!-- 标签 -->
                <div class="tags">
                    <el-tag type="primary" v-if="blogItem.tags[0]">{{blogItem.tags[0]}}</el-tag>
                    <el-tag type="success" v-if="blogItem.tags[1]">{{blogItem.tags[1]}}</el-tag>
                    <el-tag type="info" v-if="blogItem.tags[2]">{{blogItem.tags[2]}}</el-tag>
                </div>

            <!-- 媒体内容 -->
                <div class="media" v-if="blogItem.media && blogItem.media.length > 0">
                    <el-image 
                        v-for="(item, index) in blogItem.media.slice(0, 6)" 
                        :key="index"
                        :src="item"
                        fit="cover"
                        :preview-src-list="blogItem.media"
                        class="media-image"
                    />
                </div>
                </div>


            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, defineProps ,getCurrentInstance, onBeforeMount,watch} from "vue";
import {splitData,timeDifference} from '@/util/func'
import {useRouter} from 'vue-router'
import Blog from "../components/Blog.vue"
import {useStore} from '@/pinia/index.js'
import { ElMessage } from "element-plus";
const router = useRouter()
const {proxy} = getCurrentInstance()
const {chatRecord,chatAvatar} = defineProps(['chatRecord','chatAvatar'])
const store = useStore()
const blogItem = ref({
    id: '',
    userId: '',
    nickname: '',
    avatar: '',
    gender: '',
    age: '',
    bg: '',
    focusCount: 1,
    fansCount: 1,
    intro: '',
    title: '',
    tags: [],
    type: '',
    content: '',
    likeCount: '',
    commentCount: '',
    repostCount: '',
    publishIp: '',
    publishTime: '',
    media: '',
    onFocus: '',
    onLike: ''
})
onBeforeMount(() => {
  console.log("1111111111",chatRecord.value);
    if(chatRecord.type == 1){
        getBlogById()
    }
})
// 监听 chatRecord.type 的变化
watch(
  () => chatRecord.type,
  async (newType, oldType) => {
    if(oldType == 1) return
    if (newType !== oldType) {
      await getBlogById();
    }
  }
);

const getBlogById = async()=>{
    const res = await proxy.$api.ApiGetBlogById(chatRecord.content)
    res.media = splitData(res.media)
    res.publishTime = timeDifference(res.publishTime)
    res.tags = splitData(res.tags)
    blogItem.value = res
}
// 格式化时间
const formatTime = (time) => {
  return new Date(time).toLocaleString()
}

const gotoFocus = async(id,status)=>{
    // 发送请求
    const res = await proxy.$api.ApiFocusTo(id,status)
    blogItem.onFocus = status == 1?2:1
    ElMessage.success(res)
}
</script>
<style lang="scss" scoped>
.me{
    float: right;
    margin-left: 10px;
    background-color: greenyellow;
}
.other{
    float: left;
    margin-right: 10px;
    background-color: white;
}
.chatItem{
    display: block;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 15px;
    .chatAvatar,.chatAvatar img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    .chatFrame{
        margin-top: 10px;
        font-size: 28px;
        padding: 12px;
        max-width: calc(100% - 140px);
        word-break: break-all;
        white-space: pre-wrap;
    }
}
.blog-card {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-basic {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-detail {
  display: flex;
  flex-direction: column;
}
.media {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* 图片之间的间距 */
    margin-top: 16px;
}

.media-image {
    width: calc((100% - 16px) / 3); /* 每行显示 3 张图片 */
    height: 120px; /* 固定高度 */
    border-radius: 4px;
    object-fit: cover; /* 保持图片比例 */
}

@media (max-width: 768px) {
    .media-image {
        width: calc((100% - 8px) / 2); /* 在小屏幕上每行显示 2 张图片 */
    }
}
.nickname {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}

.publish-info {
  font-size: 12px;
  color: #909399;
}

.dot {
  margin: 0 5px;
}

.blog-content {
  margin-bottom: 15px;
}

.blog-title {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #303133;
}

.blog-text {
  margin: 0 0 15px 0;
  color: #606266;
  line-height: 1.6;
}

.tags {
  margin-bottom: 15px;
  display: inline-block;
  .el-tag{
    margin-right: 10px;
  }
}



</style>
