import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import test from '@/views/test.vue'
import Chat from '@/views/Chat.vue'
import PersonPg from '@/views/PersonPg.vue'
import { useStore } from '@/pinia/index.js'
import { ElMessage } from 'element-plus'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/Home.vue'),
    redirect: '/admin/dashboard',
    meta: { requiresAdmin: true }, // 标记需要管理员权限,
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/admin/dashboard/Dashboard.vue')
      },
      {
        path: 'notice',
        component: () => import('@/views/admin/notice/Notice.vue')
      },
      {
        path: 'userInfo',
        component: () => import('@/views/admin/user/Users.vue')
      },
      {
        path: 'feedback',
        component: () => import('@/views/admin/user/Feedback.vue')
      },
      {
        path: 'blogInfo',
        component: () => import('@/views/admin/blog/Blogs.vue')
      },
      {
        path: 'gifts',
        component: () => import('@/views/admin/reward/Gifts.vue')
      },
      {
        path: 'orders',
        component: () => import('@/views/admin/reward/Orders.vue')
      }
    ]
  },
  // {
  //   path: '/transitionPage',
  //   name: 'transitionPage',
  //   component:()=> import('@/views/admin/TransitionPage.vue'),
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/registy',
    name: 'registy',
    component: Login
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/personpg/:id',
    name: 'personpg',
    component: PersonPg,
    children: [
      {
        path: '',
        name: 'personHome',
        component: () => import('@/views/personpg/PersonHome.vue')
      },
      {
        path: 'blog',
        name: 'personBlog',
        component: () => import('@/views/personpg/PersonBlog.vue')
      },
      {
        path: 'like',
        name: 'personLike',
        component: () => import('@/views/personpg/PersonLike.vue')
      },
      {
        path: 'focus',
        name: 'personFocus',
        component: () => import('@/views/personpg/PersonFocus.vue')
      },
      {
        path: 'fans',
        name: 'personFans',
        component: () => import('@/views/personpg/PersonFans.vue')
      }
    ]
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    children: [
      {
        path: '',
        name: 'chatHome',
        component: () => import('@/views/chat/ChatHome.vue')
      },
      {
        path: 'friend',
        name: 'chatFriend',
        component: () => import('@/views/chat/ChatFriend.vue')
      },
      {
        path: 'search',
        name: 'chatSearch',
        component: () => import('@/views/chat/ChatSearch.vue')
      }
    ]
  },
  {
    path: '/pay/:amount/id/:id/order/:order',  // 动态参数 :amount 和 :id
    name: 'Pay',
    component: () => import('@/views/Pay.vue'), // 对应的组件
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = useStore()
  if (!from.name && to.name !== 'home' && !to.fullPath.includes('admin') && !to.fullPath.includes('pay') && !to.fullPath.includes('login') && !to.fullPath.includes('registy') && !to.fullPath.includes('test')) {
    return next({ name: 'home' }) // 跳转到目标页面
  } 
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin); // 检查是否需要管理员权限
  if (!requiresAdmin) {
    return next();
  }
  if (!store.introInfo.id || store.introInfo.id == "") {
    await store.getUserInfo()
  }
  const isAdmin = store.introInfo.admin == 1
  if (!isAdmin) {
    // 如果需要管理员权限但用户不是管理员，则重定向到登录页或首页
    ElMessage.error("没有管理员权限!")
    next({ name: 'home' });
  } else {
    next()
  }
})
export default router
