<template>
  <div class="emoji-picker">
    <el-popover
      v-model:visible="popoverVisible"
      placement="top"
      :width="360"
      trigger="click"
      popper-class="emoji-popover"
    >
      <template #reference>
        <img style="width:40px;cursor:pointer" :src="require('@/assets/svg/笑脸.svg')" />
      </template>
      
      <div class="emoji-container">
        <div class="emoji-tabs">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="常用" name="frequent">
              <div class="emoji-grid">
                <div 
                  v-for="emoji in frequentEmojis" 
                  :key="emoji"
                  class="emoji-item"
                  @click="selectEmoji(emoji)"
                >
                  {{ emoji }}
                </div>
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="表情" name="smileys">
              <div class="emoji-grid">
                <div 
                  v-for="emoji in smileyEmojis" 
                  :key="emoji"
                  class="emoji-item"
                  @click="selectEmoji(emoji)"
                >
                  {{ emoji }}
                </div>
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="动物" name="animals">
              <div class="emoji-grid">
                <div 
                  v-for="emoji in animalEmojis" 
                  :key="emoji"
                  class="emoji-item"
                  @click="selectEmoji(emoji)"
                >
                  {{ emoji }}
                </div>
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="食物" name="food">
              <div class="emoji-grid">
                <div 
                  v-for="emoji in foodEmojis" 
                  :key="emoji"
                  class="emoji-item"
                  @click="selectEmoji(emoji)"
                >
                  {{ emoji }}
                </div>
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="活动" name="activities">
              <div class="emoji-grid">
                <div 
                  v-for="emoji in activityEmojis" 
                  :key="emoji"
                  class="emoji-item"
                  @click="selectEmoji(emoji)"
                >
                  {{ emoji }}
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Face } from '@element-plus/icons-vue'

const props = defineProps({
  size: {
    type: String,
    default: 'default'
  }
})

const emit = defineEmits(['select'])

const popoverVisible = ref(false)
const activeTab = ref('frequent')

// 常用表情
const frequentEmojis = [
  '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', 
  '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙',
  '👍', '👌', '✌️', '🙏', '🤝', '👏', '❤️', '🔥'
]

// 表情符号
const smileyEmojis = [
  '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', 
  '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩',
  '😘', '😗', '😚', '😙', '😋', '😛', '😜', '🤪', 
  '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨',
  '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤥', 
  '😌', '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕',
  '🤢', '🤮', '🤧', '🥵', '🥶', '🥴', '😵', '🤯', 
  '🤠', '🥳', '😎', '🤓', '🧐', '😕', '😟', '🙁',
  '☹️', '😮', '😯', '😲', '😳', '🥺', '😦', '😧', 
  '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣',
  '😞', '😓', '😩', '😫', '🥱', '😤', '😡', '😠', 
  '🤬', '😈', '👿', '💀', '☠️', '💩', '🤡', '👹'
]

// 动物表情
const animalEmojis = [
  '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼',
  '🐨', '🐯', '🦁', '🐮', '🐷', '🐽', '🐸', '🐵',
  '🙈', '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤',
  '🐣', '🐥', '🦆', '🦅', '🦉', '🦇', '🐺', '🐗',
  '🐴', '🦄', '🐝', '🐛', '🦋', '🐌', '🐞', '🐜',
  '🦟', '🦗', '🕷', '🕸', '🦂', '🐢', '🐍', '🦎',
  '🦖', '🦕', '🐙', '🦑', '🦐', '🦞', '🦀', '🐡',
  '🐠', '🐟', '🐬', '🐳', '🐋', '🦈', '🐊', '🐅'
]

// 食物表情
const foodEmojis = [
  '🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇',
  '🍓', '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝',
  '🍅', '🍆', '🥑', '🥦', '🥬', '🥒', '🌶', '🌽',
  '🥕', '🧄', '🧅', '🥔', '🍠', '🥐', '🥯', '🍞',
  '🥖', '🥨', '🧀', '🥚', '🍳', '🧈', '🥞', '🧇',
  '🥓', '🥩', '🍗', '🍖', '🦴', '🌭', '🍔', '🍟',
  '🍕', '🥪', '🥙', '🧆', '🌮', '🌯', '🥗', '🥘',
  '🥫', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱', '🥟'
]

// 活动表情
const activityEmojis = [
  '⚽️', '🏀', '🏈', '⚾️', '🥎', '🎾', '🏐', '🏉',
  '🥏', '🎱', '🪀', '🏓', '🏸', '🏒', '🏑', '🥍',
  '🏏', '🥅', '⛳️', '🪁', '🏹', '🎣', '🤿', '🥊',
  '🥋', '🎽', '🛹', '🛷', '⛸', '🥌', '🎿', '⛷',
  '🏂', '🪂', '🏋️', '🤼', '🤸', '🤺', '⛹️', '🤾',
  '🏌️', '🏇', '🧘', '🏄', '🏊', '🤽', '🚣', '🧗',
  '🚴', '🚵', '🎮', '🕹', '🎲', '🎯', '🎳', '🎭',
  '🎨', '🎬', '🎤', '🎧', '🎼', '🎹', '🥁', '🎷'
]

// 选择表情
const selectEmoji = (emoji) => {
  emit('select', emoji)
  popoverVisible.value = false
  
  // 将选中的表情添加到常用表情的前面
  if (activeTab.value !== 'frequent' && !frequentEmojis.includes(emoji)) {
    frequentEmojis.pop() // 移除最后一个
    frequentEmojis.unshift(emoji) // 添加到开头
  }
}
</script>

<style scoped>
.emoji-button {
  font-size: 20px;
}

.emoji-container {
  max-height: 370px;
  overflow-y: auto;
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
  padding: 12px;
}

.emoji-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.emoji-item:hover {
  background-color: #f0f0f0;
}

:deep(.emoji-popover) {
  padding: 0;
  max-width: 90vw;
}

:deep(.el-tabs__header) {
  margin-bottom: 0;
}

:deep(.el-tabs__nav) {
  width: 100%;
  display: flex;
}

:deep(.el-tabs__item) {
  flex: 1;
  text-align: center;
}

/* 自定义滚动条样式 */
.emoji-container::-webkit-scrollbar {
  width: 6px;
}

.emoji-container::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
}

.emoji-container::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .emoji-grid {
    grid-template-columns: repeat(7, 1fr);
  }
  
  .emoji-item {
    height: 36px;
    width: 36px;
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .emoji-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 6px;
    padding: 8px;
  }
  
  .emoji-item {
    height: 32px;
    width: 32px;
    font-size: 20px;
  }
}
</style> 