import constants from '@/common/common.js';

/**
 * 根据传入的时间与现在的时间差
 * 将时间差转成可读字符串 如:刚刚、3秒前、一小时前等等
 *
 * @param time 需要进行比较的毫秒级时间(毫秒 同时需要 小于 当前时间)(这里通过LTD转为时间戳)
 * @return 返回可读字符串, 大于20天的 将会直接显示日期
 */
export function timeDifference(time) {
    if (time === null || time === undefined) {
      return ''
    }
   let date = new Date(time)
   let timestamp = date.getTime();
    // 获取两个日期之间相差的毫秒数
    const timeDifference = Math.abs(Date.now() - timestamp)

    const days = timeDifference / 1000 / 60 / 60 / 24
    const hours = timeDifference / 1000 / 60 / 60 - (24 * Math.floor(days))
    const minutes = timeDifference / 1000 / 60 - (24 * 60 * Math.floor(days)) - (60 * Math.floor(hours))
    const seconds = timeDifference / 1000 - (24 * 60 * 60 * Math.floor(days)) - (60 * 60 * Math.floor(hours)) - (60 * Math.floor(minutes))
   
    // 1分钟以内(1分钟 = 60000ms)
    if (timeDifference < 60000) {
      // 如果是5秒内
      const just = 5
      if (timeDifference < 1000 * just) {
        return '刚刚'
      } else {
        return Math.floor(seconds) + '秒前'
      }
    }
   
    // 一小时内(大于 1分钟[60000ms] 小于 1小时[3600000ms])
    if (timeDifference >= 60000 && timeDifference < 3600000) {
      return Math.floor(minutes) + '分钟前'
    }
   
    // 一天内
    if (timeDifference >= 3600000 && timeDifference < 86400000) {
      return Math.floor(hours) + '小时前'
    }
   
    // 二十天内
    const thirtyDays = 86400000 * 30
    if (timeDifference >= 86400000 && timeDifference < thirtyDays) {
      return Math.floor(days) + '天前'
    }
   
    return '很久之前'
  }

  export function splitData(data){
    if(data==''||data==null){
      return []
    }else{
      return data.split(',')
    }
  }
export function getCurrentTimeFormatted() {
  // 创建一个新的Date对象，表示当前时间
  const now = new Date();

  // 提取年、月、日、小时、分钟、秒和毫秒
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并确保是两位数
  const day = String(now.getDate()).padStart(2, '0'); // 确保日期是两位数
  const hours = String(now.getHours()).padStart(2, '0'); // 确保小时是两位数
  const minutes = String(now.getMinutes()).padStart(2, '0'); // 确保分钟是两位数
  const seconds = String(now.getSeconds()).padStart(2, '0'); // 确保秒是两位数
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0'); // 确保毫秒是三位数

  // 拼接成所需的格式
  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedTime;
}

export function getCurrentDateTime() {
  const now = new Date();

  // 获取年、月、日、时、分、秒
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要 +1
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // 拼接成 YYYY-MM-DDTHH:mm:ss 格式
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}
export function connectWs(store,id,info){
  var ws = null
  function connect() {
    if(ws == null){
    // ws = new WebSocket(`ws://39.103.58.175:8002/mychat/${id}/${info}`);

    ws = new WebSocket(`${constants.WEBSOCKET_BASE_URL}/${id}/${info}`);
    ws.onopen = function(event) {
        console.log('连接成功');
    };

    ws.onclose = function(event) {
      ws == null
        console.log('连接关闭');
        setTimeout(()=>{
          connect()
        }, 2000) // 5秒后尝试重新连接
    };

    ws.onerror = function(event) {
        console.error('连接出错', event);
        ws.close()
    };

    ws.onmessage = function(e) {
      if(info == "1"){
        store.unreadCount++
        console.log("1111111111111110",store.unreadCount);
      }else{
        const data = JSON.parse(e.data)
        let index = -1
        let res = store.chatUserList.find(item=>{
          index++
          if(item.id == data.userInfoDto.id){
            console.log(item.unread == undefined,"79797979779");
            item.unread == undefined?item.unread = 1:item.unread++
            item.chatRecord.push(data.userMsg)
            let tmpobj = {}
            tmpobj = store.chatUserList[0]
            store.chatUserList[0] = store.chatUserList[index]
            store.chatUserList[index] = tmpobj
            return true
          }
        })
        if(res == undefined){
          data.userInfoDto.chatRecord = []
          data.userInfoDto.unread = 1
          data.userInfoDto.chatRecord.push(data.userMsg)
          store.chatUserList.unshift(data.userInfoDto)
        }
      }
      
    };
    window.addEventListener('beforeunload', function(event) {
      ws.close
      ws = null
  });
  }}
  connect()
  return ws
}
export function timeFormattd(dateString){
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}