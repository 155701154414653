<template>
  <Header />
  <div class="personpgcontainer">
    <div class="personpg-left-card">
      <h1>个人主页</h1>
      <el-menu :default-active="route.path" router>
        <el-menu-item :index="'/personpg/' + pathId">
          <img :src="require('@/assets/svg/我的主页.svg')" class="svg" />
          <span>{{ isMe ? "我的主页" : "TA的主页" }}</span>
        </el-menu-item>
        <el-menu-item :index="'/personpg/' + pathId + '/blog'">
          <img :src="require('@/assets/svg/博客.svg')" class="svg" />
          <span>{{ isMe ? "我的博客" : "TA的博客" }}</span>
        </el-menu-item>
        <el-menu-item :index="'/personpg/' + pathId + '/like'">
          <img :src="require('@/assets/svg/点赞.svg')" class="svg" />
          <span>{{ isMe ? "我的点赞" : "TA的点赞" }}</span>
        </el-menu-item>
        <el-menu-item :index="'/personpg/' + pathId + '/focus'">
          <img :src="require('@/assets/svg/我的关注.svg')" class="svg" />
          <span>{{ isMe ? "我的关注" : "TA的关注" }}</span>
        </el-menu-item>
        <el-menu-item :index="'/personpg/' + pathId + '/fans'">
          <img :src="require('@/assets/svg/粉丝趴.svg')" class="svg" />
          <span>{{ isMe ? "我的粉丝" : "TA的粉丝" }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="personpg-main-card">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script setup>
import Header from "../components/Header";
import { ref, onMounted } from "vue";
import { useRoute,useRouter } from "vue-router";
import { useStore } from "@/pinia/index.js";

const store = useStore();
const isMe = ref(false);
const route = useRoute();
const router = useRouter();
const pathId = ref(null);

onMounted(() => {
  pathId.value = route.params.id;
  if (store.introInfo.id == route.params.id) {
    isMe.value = true;
  }
});
</script>

<style lang="scss" scoped>
.personpgcontainer {
  position: fixed;
  top: 85px;
  width: 100%;
  height: calc(100% - 85px);
  .personpg-left-card {
    .el-menu {
      border-right: 0;
      .iconfont {
        margin-left: -10px;
        margin-right: 10px;
      }
      :deep(span) {
        font-size: 20px;
      }
    }
    padding: 0 10px;
    h1 {
      font-size: 28px;
      margin-bottom: 15px;
      margin-left: 10px;
    }
    padding-top: 30px;
    border-radius: 10px;
    position: absolute;
    width: 12%;
    height: 800px;
    left: 12%;
    background-color: white;
  }
  .personpg-main-card {
    width: 45%;
    margin-left: 25%;
    min-height: 100%;
    border-radius: 0 40px 0 40px;
    background-color: white;
    overflow-y: auto; /* 允许垂直滚动 */
    height: 90vh; /* 设置父容器高度为视口高度 */
  }
}
.svg {
  margin-right: 5px;
}
</style>