import { ElMessage } from "element-plus";
import { defineStore } from "pinia";
import service from "@/api/request.js";
export const useStore = defineStore('myInfo',{
    persist: false,
    state:()=>{
        return{
            unreadCount:0,
            introInfo:{},
            // introInfo :{
            //     avatar:'',
            //     id:"",
            //     nickname:'',
            //     age:'',
            //     gender:'',
            //     bg:'',
            //     intro:'',
            //     focusCount:'',
            //     fansCount:'',
            //   },
            chatUserList:[
                {
                    id:'000000',
                    nickname:'AI',
                    avatar:'http://39.103.58.175:9000/mychat-miniprogram/user/avatar/avatar.png',
                    chatRecord:[
                        {
                            sendId:'000000',
                            recId:'100000',
                            type:0,
                            content:'我是机器人',
                            status:false,
                            time:"2024-12-08 15:50:03.0"
                        }
                    ]
                }
            ],
            // getUserInfo:async()=>{
            //     try {
            //         const res = await service.get('http://localhost:10010/webuser/user/-1'); // 替换为你的 API 地址
            //         introInfo = res
            //         console.log(res,"r");
            //       } catch (err) {
            //         ElMessage.error(err)
            //       } 
            // }
        }
    },
    actions: {
        // 异步方法：获取用户信息
        async getUserInfo() {
          try {
            const res = await service.get('webuser/user/-1'); // 替换为你的 API 地址
            this.introInfo = res; // 将请求结果赋值给 state 中的 introInfo
            console.log(res);
          } catch (err) {
            ElMessage.error(err.message || '获取用户信息失败');
          }
        },
      }
})