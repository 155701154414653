<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/logo.png" />
      <div class="line"></div>
      <span>茄特</span>
    </div>
    <div class="nav">
      <span @click="router.push('/')"> 首页 </span>
      <span @click="router.push('/chat/search')"> 搜索 </span>
      <span @click="router.push('/chat')"> 私信 </span>
      <span @click="backVisable = true"> 反馈 </span>
    </div>
    <div class="avator">
      <img
        :src="
          !store.introInfo.avatar
            ? require('@/assets/avatar.webp')
            : store.introInfo.avatar
        "
        alt=""
      />
      <div
        class="online"
        :style="{
          'background-color': store.introInfo.id ? 'greenyellow' : 'red',
        }"
      ></div>
      <el-badge is-dot :hidden="store.unreadCount == 0">
        <el-select v-model="value" placeholder="我的" style="width: 135px">
          <el-option
            style="color: grey"
            @click="router.push(`/personpg/${store.introInfo.id}`)"
            label="我的主页"
            :value="我的主页"
          >
          </el-option>
          <el-badge
            :value="store.unreadCount"
            :max="99"
            :hidden="store.unreadCount == 0"
          >
            <el-option
              style="color: grey"
              @click="router.push('/chat')"
              label="我的私信"
              :value="我的私信"
            >
            </el-option>
          </el-badge>
          <el-option
            style="color: grey"
            @click="navigate"
            label="娱乐空间"
            :value="娱乐空间"
          >
          </el-option>
          <el-option
            style="color: grey"
            label="我的余额"
            @click="showBalancePop"
            :value="我的余额"
          >
          </el-option>
          <el-option
            style="color: grey"
            label="收益记录"
            @click="showIncome"
            :value="收益记录"
          >
          </el-option>
          <el-option
            style="color: grey"
            @click="logoutVisible = true"
            label="退出登录"
            :value="退出登录"
          >
          </el-option>
        </el-select>
      </el-badge>
    </div>
    <el-dialog
      v-model="logoutVisible"
      title="退出登录"
      width="30%"
      :show-close="false"
      center
      class="logoutDialog"
    >
      <div class="confirm-content">
        <el-icon class="warning-icon"><WarningFilled /></el-icon>
        <p class="confirm-text">确定要退出吗</p>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="logoutVisible = false">取消</el-button>
          <el-button type="danger" :loading="loading" @click="ConfirmLogout">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>

  <!-- 反馈对话框 -->
  <el-dialog
    v-model="backVisable"
    title="意见反馈"
    width="450px"
    :show-close="true"
    destroy-on-close
    center
  >
    <div class="feedback-content">
      <div class="feedback-header">
        <el-icon class="feedback-icon"><Edit /></el-icon>
        <p class="feedback-tip">您的反馈对我们很重要</p>
      </div>

      <el-input
        v-model="feedbackContent"
        type="textarea"
        :rows="4"
        placeholder="请输入您的反馈内容..."
        resize="none"
        maxlength="500"
        show-word-limit
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button plain @click="handleFeedBackCancel"> 取消 </el-button>
        <el-button
          type="primary"
          :loading="backloading"
          :disabled="!feedbackContent.trim()"
          @click="handleSubmitFeedBack"
        >
          提交反馈
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 余额对话框 -->
  <el-dialog
    v-model="balanceDialogVisible"
    title="我的余额"
    width="400px"
    center
  >
    <div class="balance-info">
      <div class="balance-amount">
        <div class="amount-value">{{ userBalance }}</div>
        <div class="amount-label">茄特币</div>
      </div>
      <div class="balance-hint">(100茄特币 = 1人民币)</div>
      <div class="balance-rmb">
        约
        <span class="rmb-value">{{ (userBalance / 100).toFixed(2) }}</span> 元
      </div>
    </div>

    <div class="balance-actions">
      <el-button type="primary" @click="handleRecharge">充值</el-button>
      <el-button type="success" @click="handleWithdraw">提现</el-button>
    </div>

    <!-- 交易记录 -->
    <div class="transaction-history">
      <div class="history-title">近一周交易记录</div>
      <el-scrollbar height="150px">
        <div v-if="transactions.length">
          <div
            v-for="(item, index) in transactions"
            :key="index"
            class="transaction-item"
          >
            <div class="transaction-info">
              <div class="transaction-type">
                {{ item.type == 0 ? "充值" : item.type == 1 ? "提现" : "打赏" }}
              </div>
              <div class="transaction-time">
                {{ timeFormattd(item.create_time) }}
              </div>
            </div>
            <div class="transaction-amount" :class="{ income: item.type == 0 }">
              {{ item.type == 0 ? "+" : "-"
              }}{{ item.type == 3 ? item.amount : item.amount * 100 }}茄币
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无交易记录" :image-size="60" />
      </el-scrollbar>
    </div>
  </el-dialog>

  <!-- 充值对话框 -->
  <el-dialog
    :close-on-click-modal="false"
    :show-close="false"
    v-model="rechargeDialogVisible"
    :title="confirmPayFlag ? '请选择充值金额' : '请在三分钟内完成充值'"
    width="350px"
    center
  >
    <div class="recharge-content">
      <div class="qrcode-container" v-if="!confirmPayFlag">
        <img
          :src="
            constants.QRCODE_BASE_URL +
            '/pay/' +
            rechargeRmb +
            '/id/' +
            token +
            '/order/' +
            orderId
          "
          alt="收款码"
          class="qrcode-image"
        />
      </div>
      <div class="recharge-hint" v-if="!confirmPayFlag">
        <p>请使用微信扫码支付</p>
        <p>充值比例：1元 = 100茄特币</p>
      </div>
      <div class="recharge-amount" v-if="confirmPayFlag">
        <el-input-number
          v-model="rechargeAmount"
          :min="10"
          :max="10000"
          :step="10"
          @change="updateRechargeRmb"
        />
        <span class="amount-unit">虚拟币</span>
      </div>
      <div class="recharge-rmb">
        需支付：<span class="rmb-value">{{ rechargeRmb }}</span> 元
      </div>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="concelRecharge">取消</el-button>
        <el-button
          type="primary"
          @click="confirmRecharge"
          v-if="confirmPayFlag"
        >
          确认金额
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 提现对话框 -->
  <el-dialog v-model="withdrawDialogVisible" title="提现" width="400px" center>
    <div class="withdraw-content">
      <div class="withdraw-info">
        <div class="available-balance">
          可提现余额：<span class="balance-value">{{ userBalance }}</span>
          茄特币
        </div>
        <div class="withdraw-hint">
          (最低提现100茄特币，提现金额必须是100的整数倍)
        </div>
        <div class="withdraw-hint">(平台提现收取20%)</div>
      </div>

      <el-form
        :model="withdrawForm"
        :rules="withdrawRules"
        ref="withdrawFormRef"
        label-width="100px"
      >
        <el-form-item label="提现金额" prop="amount">
          <el-input-number
            v-model="withdrawForm.amount"
            :min="100"
            :max="userBalance"
            :step="100"
            @change="updateWithdrawRmb"
            style="width: 200px"
          />
          <span class="amount-unit">虚拟币</span>
        </el-form-item>

        <div class="withdraw-rmb">
          实际到账：<span class="rmb-value">{{ withdrawRmb }}</span> 元
        </div>

        <el-form-item label="收款方式" prop="method">
          <el-select
            v-model="withdrawForm.method"
            placeholder="请选择收款方式"
            style="width: 100%"
          >
            <el-option label="微信" value="wechat" />
          </el-select>
        </el-form-item>

        <el-form-item label="收款账号" prop="account">
          <el-input
            v-model="withdrawForm.account"
            placeholder="请输入收款账号"
          />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="withdrawDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="toWithdraw"
          :loading="withdrawLoading"
        >
          确认提现
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 支付成功弹窗 -->
  <el-dialog
    v-model="paymentDialogVisible"
    title="支付成功"
    width="30%"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="payment-success-content">
      <el-icon color="#67C23A" :size="60">
        <CircleCheck />
      </el-icon>
      <p class="success-message">支付成功！</p>
      <p class="order-info">订单号: {{ orderId }}</p>
      <p class="amount">支付金额: ¥{{ rechargeRmb }}</p>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <p class="auto-close-tip">窗口将在 {{ countdown }} 秒后自动关闭...</p>
      </div>
    </template>
  </el-dialog>

  <!-- 密码输入弹窗 -->
  <el-dialog
    v-model="psdVisable"
    title="请输入您的密码"
    width="30%"
    :close-on-click-modal="false"
  >
    <el-input
      v-model="withdrawForm.password"
      type="password"
      placeholder="请输入您的登录密码"
      show-password
      clearable
    />
    <template #footer>
      <el-button @click="psdVisable = false">取消</el-button>
      <el-button type="primary" @click="confirmWithdraw" :loading="loading">
        确认充值
      </el-button>
    </template>
  </el-dialog>
  <!-- 收益记录 -->
  <el-dialog v-model="incomeVisiable" title="一周内收益记录" width="600px">
    <div class="dialog-header">
      <div class="total-info">
        <div class="total-amount">
          <span class="label">总收益：</span>
          <span class="value">{{ totalAmount }}</span>
          <span class="unit">虚拟币</span>
        </div>
        <div class="total-count">
          <span class="label">打赏次数：</span>
          <span class="value">{{ incomeRecords.length }}</span>
          <span class="unit">次</span>
        </div>
      </div>
    </div>

    <el-divider />

    <div class="record-list" v-loading="loading">
      <template v-if="incomeRecords.length">
        <div
          v-for="(record, index) in incomeRecords"
          :key="index"
          class="record-item"
        >
          <div class="user-info">
            <el-avatar :size="40" :src="record.avatar"></el-avatar>
            <div class="user-detail">
              <div class="user-name">{{ record.nickname }}</div>
              <div class="reward-time">
                {{ timeFormattd(record.create_time) }}
              </div>
            </div>
          </div>
          <div class="gift-info">
            <div class="gift-detail">
              <div class="gift-price">+ {{ record.amount }} 茄特币</div>
            </div>
          </div>
        </div>
      </template>

      <el-empty v-else description="暂无打赏记录" :image-size="80" />
    </div>
  </el-dialog>
</template>

<script setup>
import constants from "@/common/common.js";
import { useStore } from "@/pinia/index";
import { useRouter } from "vue-router";
import { ElMessage, ElNotification } from "element-plus";
import { timeFormattd } from "@/util/func.js";
import { ref, getCurrentInstance, computed, watch } from "vue";
// 弹窗控制
const incomeVisiable = ref(false);
const paymentDialogVisible = ref(false);
const countdown = ref(2);
const timer = ref(null);
const orderId = ref("");
const backVisable = ref(false);
const { proxy } = getCurrentInstance();
const logoutVisible = ref(false);
const backloading = ref(false);
const feedbackContent = ref("");
const loading = ref(false);
const router = useRouter();
const psdVisable = ref(false);
const store = useStore();
// 对话框显示控制
const balanceDialogVisible = ref(false);
const rechargeDialogVisible = ref(false);
const withdrawDialogVisible = ref(false);
const retryTimes = ref(0);
const token = sessionStorage.getItem("LOGIN_TOKEN");
const completePay = ref(false);
// 用户余额
const userBalance = ref(0);

// 充值相关
const rechargeAmount = ref(100);
const rechargeRmb = computed(() => (rechargeAmount.value / 100).toFixed(2));
// 打赏记录数据
const incomeRecords = ref([]);
// 计算总收益
const totalAmount = computed(() => {
  return incomeRecords.value.reduce((sum, record) => sum + record.amount, 0);
});
// 提现相关
const withdrawForm = ref({
  amount: 100,
  method: "",
  account: "",
  password: "",
});
const withdrawRmb = computed(() =>
  ((withdrawForm.value.amount / 100) * 0.8).toFixed(2)
);
const withdrawLoading = ref(false);
const withdrawFormRef = ref(null);
const showBalancePop = async () => {
  balanceDialogVisible.value = true;
  userBalance.value = await proxy.$api.ApiGetBalance();
  transactions.value = await proxy.$api.ApiGetUserOder(store.introInfo.id);
};
const confirmPayFlag = ref(true);
// 提现表单验证规则
const withdrawRules = {
  amount: [
    { required: true, message: "请输入提现金额", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        if (value % 100 !== 0) {
          callback(new Error("提现金额必须是100的整数倍"));
        } else if (value > userBalance.value) {
          callback(new Error("提现金额不能大于可用余额"));
        } else {
          callback();
        }
      },
      trigger: "change",
    },
  ],
  method: [{ required: true, message: "请选择收款方式", trigger: "change" }],
  account: [
    { required: true, message: "请输入收款账号", trigger: "blur" },
    { min: 6, message: "账号长度不能小于6位", trigger: "blur" },
  ],
};

// 显示支付成功弹窗
const showPaymentDialog = () => {
  // 模拟支付过程
  paymentDialogVisible.value = true;
  startCountdown();
};

// 倒计时关闭
const startCountdown = () => {
  countdown.value = 3;
  clearInterval(timer.value);

  timer.value = setInterval(() => {
    countdown.value -= 1;
    if (countdown.value <= 0) {
      clearInterval(timer.value);
      paymentDialogVisible.value = false;
    }
  }, 1000);
};
const toWithdraw = async () => {
  try {
    await withdrawFormRef.value.validate();
  } catch {
    return;
  }
  if (withdrawForm.value.amount > userBalance) {
    return ElMessage.warning("余额不足!");
  }
  psdVisable.value = true;
};
// 监听弹窗关闭
watch(paymentDialogVisible, (newVal) => {
  if (!newVal) {
    clearInterval(timer.value);
  }
});
// 交易记录
const transactions = ref([]);

// 处理充值按钮点击
const handleRecharge = () => {
  // 生成订单号
  orderId.value =
    "Order" + Date.now().toString()
  balanceDialogVisible.value = false;
  rechargeDialogVisible.value = true;
};
const vertifyPay = async () => {
  if (++retryTimes.value >= 36) {
    retryTimes.value = 0;
    confirmPayFlag.value = true;
    if (completePay.value) {
      return;
    } else {
      completePay.value = false;
      return ElMessage.warning("支付超时");
    }
  }
  try {
    const res = await proxy.$api.ApiVetifyPay({ orderId: orderId.value });
    //发起请求让后端添加订单增余额
    retryTimes.value = 999;
    await proxy.$api.ApiCompletePay(res);
    completePay.value = true;
    showPaymentDialog();
    rechargeDialogVisible.value = false;
    confirmPayFlag.value = true;
  } catch {
  } finally {
    // 无论成功失败，5秒后都再次请求
    setTimeout(vertifyPay, 5000);
  }
};
// 更新充值人民币金额
const updateRechargeRmb = (value) => {
  rechargeAmount.value = value;
};

// 确认充值
const confirmRecharge = () => {
  confirmPayFlag.value = false;
  setTimeout(() => {
    vertifyPay();
  }, 5000);
};
const concelRecharge = () => {
  rechargeDialogVisible.value = false;
  confirmPayFlag.value = true;
  retryTimes.value = 0;
};
// 处理提现按钮点击
const handleWithdraw = () => {
  balanceDialogVisible.value = false;
  withdrawDialogVisible.value = true;
  withdrawForm.value.amount = 100;
  withdrawForm.value.method = "";
  withdrawForm.value.account = "";
};

// 更新提现人民币金额
const updateWithdrawRmb = (value) => {
  withdrawForm.value.amount = value;
};

// 确认提现
const confirmWithdraw = async () => {
  if (!withdrawFormRef.value) return;

  try {
    await withdrawFormRef.value.validate();

    withdrawLoading.value = true;
    // 模拟提现请求
    await proxy.$api.ApiWithdraw(withdrawForm.value);

    psdVisable.value = false;
    ElMessage.success(
      `提现申请已提交，${withdrawRmb.value}￥将在1-3个工作日内到账`
    );
    withdrawDialogVisible.value = false;
  } catch (error) {
  } finally {
    withdrawForm.value.password = "";
    withdrawLoading.value = false;
  }
};

const navigate = () => {
  window.location.href = "http://wangqilin.space3v.work/";
};
// 显示反馈框
const showFeedback = () => {
  dialogVisible.value = true;
};
const handleFeedBackCancel = () => {
  backVisable.value = false;
  feedbackContent.value = "";
};
const ConfirmLogout = () => {
  logoutVisible.value = false;
  store.$reset();
  router.push("/login");
  sessionStorage.removeItem("LOGIN_TOKEN");
};
const handleSubmitFeedBack = async () => {
  if (!feedbackContent.value.trim()) return;
  if (feedbackContent.value.trim().length > 100)
    return ElMessage.error("反馈内容不能超过100个字!");
  backloading.value = true;
  const res = await proxy.$api.ApiFeedBack(feedbackContent.value.trim());
  backVisable.value = false;
  feedbackContent.value = "";
  backloading.value = false;
  ElMessage.success(res);
};
//收益记录
const showIncome = async () => {
  incomeVisiable.value = true;
  await getIncomeRecords();
};

// 获取打赏记录
const getIncomeRecords = async () => {
  loading.value = true;
  try {
    incomeRecords.value = await proxy.$api.ApiGetIncome();
  } catch (error) {
    console.error("获取打赏记录失败", error);
    ElMessage.error("获取打赏记录失败");
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 70px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-around;
  .logo {
    line-height: 60px;
    height: 60px;
    width: 120px;
    display: flex;
    justify-content: center;
    margin-left: -150px;
    img {
      vertical-align: top;
      height: 60px;
      width: 60px;
      margin-right: 10px;
    }
    .line {
      width: 0;
      height: 60px;
      border: 1px solid black;
      margin-right: 10px;
    }
  }
  .nav {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  .avator {
    position: relative;
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .online {
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      left: 40px;
      top: 40px;
    }
    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 1px gainsboro solid;
    }
    .el-select {
      width: 100px;
    }
  }
  .logoutDialog {
    z-index: 109;
  }
}
balance-manager {
  display: inline-block;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #409eff;
}

.balance-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.balance-amount {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.amount-value {
  font-size: 36px;
  font-weight: bold;
  color: #409eff;
}

.amount-label {
  font-size: 16px;
  color: #606266;
  margin-left: 5px;
}

.balance-hint {
  font-size: 12px;
  color: #909399;
  margin-bottom: 5px;
}

.balance-rmb {
  font-size: 14px;
  color: #606266;
}

.rmb-value {
  color: #f56c6c;
  font-weight: bold;
}

.balance-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.transaction-history {
  margin-top: 20px;
  border-top: 1px solid #ebeef5;
  padding-top: 15px;
}

.history-title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 10px;
  font-weight: bold;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ebeef5;
}

.transaction-item:last-child {
  border-bottom: none;
}

.transaction-type {
  font-size: 14px;
  color: #303133;
}

.transaction-time {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

.transaction-amount {
  font-size: 16px;
  color: #f56c6c;
  font-weight: bold;
}

.transaction-amount.income {
  color: #67c23a;
}

.qrcode-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.qrcode-image {
  width: 200px;
  height: 200px;
  border: 1px solid #ebeef5;
}

.recharge-hint {
  text-align: center;
  margin-bottom: 20px;
  color: #606266;
  font-size: 14px;
}

.recharge-hint p {
  margin: 5px 0;
}

.recharge-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.amount-unit {
  margin-left: 10px;
  color: #606266;
}

.recharge-rmb {
  text-align: center;
  color: #606266;
  font-size: 14px;
}

.withdraw-content {
  padding: 10px 0;
}

.withdraw-info {
  margin-bottom: 20px;
}

.available-balance {
  font-size: 16px;
  color: #606266;
  margin-bottom: 5px;
}

.balance-value {
  color: #409eff;
  font-weight: bold;
}

.withdraw-hint {
  font-size: 12px;
  color: #909399;
}

.withdraw-rmb {
  margin: 10px 0 20px 100px;
  color: #606266;
  font-size: 14px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.payment-success-content {
  text-align: center;
  padding: 20px 0;
}

.success-message {
  font-size: 18px;
  font-weight: bold;
  color: #67c23a;
  margin: 15px 0;
}

.order-info,
.amount {
  margin: 8px 0;
  color: #606266;
}

.dialog-footer {
  text-align: center;
}

.auto-close-tip {
  color: #909399;
  font-size: 14px;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.total-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.total-amount,
.total-count {
  display: flex;
  align-items: baseline;
}

.label {
  font-size: 14px;
  color: #606266;
}

.value {
  font-size: 18px;
  font-weight: bold;
  color: #409eff;
  margin: 0 5px;
}

.unit {
  font-size: 14px;
  color: #909399;
}

.record-list {
  max-height: 400px;
  overflow-y: auto;
}

.record-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ebeef5;
}

.record-item:last-child {
  border-bottom: none;
}

.gift-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.gift-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.gift-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gift-name {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
}

.gift-price {
  font-size: 14px;
  color: #08f527;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-name {
  font-size: 14px;
  color: #303133;
}

.reward-time {
  font-size: 12px;
  color: #909399;
}
</style>