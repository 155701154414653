const constants = {
    // //本地
    // REQUEST_BASE_URL:"http://localhost:10010",
    // WEBSOCKET_BASE_URL:"ws://localhost:8002/mychat",
    // QRCODE_BASE_URL:"http://localhost:10010/webuser/pay/qrCode?url=http://192.168.20.87:8081",
    // CONFIRM_PAY:"http://192.168.20.87:10010/webuser/pay/confirm"


    // 线上
    REQUEST_BASE_URL:"http://39.103.58.175:10010",
    WEBSOCKET_BASE_URL:"ws://39.103.58.175:8002/mychat",
    QRCODE_BASE_URL:"http://39.103.58.175:10010/webuser/pay/qrCode?url=http://www.keelier.com",
    CONFIRM_PAY:"http://39.103.58.175:10010/webuser/pay/confirm"
}
export default constants