import service from "./request";

export default {
    ApigetCaptchaInfo() {    //获取base64和随机数
        return service.get('/webuser/login/captcha')
    },
    ApiGotoLogin(logininfo) {    //用户登录
        return service({
            method: 'POST',
            url: '/webuser/login',
            data: logininfo
        })
    },
    ApiAdminLogin(logininfo) {    //admin登录
        return service({
            method: 'POST',
            url: '/webuser/admin/login',
            data: logininfo
        })
    },
    gotoRegistyApi(registyInfo) {
        return service({
            method: 'POST',
            url: '/webuser/registy',
            data: registyInfo
        })
    },
    ApiAdminGetUserList(param) {  //admin获取用户数据
        return service({
            method: 'POST',
            url: '/webuser/admin/users',
            data: param
        })
    },
    ApiAdminResetPsd(id) {  //admin获取重置密码
        return service({
            method: 'POST',
            url: '/webuser/admin/resetPsd',
            data: id
        })
    },
    ApiAdminChangeStatus(param) {  //admin修改用户状态
        return service({
            method: 'POST',
            url: '/webuser/admin/status',
            data: param
        })
    },
    ApiAdminGetFeedBack(param) {  //admin获得反馈
        return service({
            method: 'POST',
            url: '/webuser/admin/feedback',
            data: param
        })
    },
    ApiAdminRemoveFeedBack(id) {  //admin删除反馈
        return service({
            method: 'POST',
            url: '/webuser/admin/removeBack',
            data: id
        })
    },
    ApiAdminGetBlogList(param) {  //admin获取blog列表
        return service({
            method: 'POST',
            url: '/webuser/admin/getBlogList',
            data: param
        })
    },
    ApiAdminRemoveBlog(param) {  //admin删除blog
        return service({
            method: 'POST',
            url: '/webuser/admin/removeBlog',
            data: param
        })
    },
    ApiAdminGetNotice(param) {  //admin获取公告
        return service({
            method: 'POST',
            url: '/webuser/admin/getNotice',
            data: param
        })
    },
    ApiAdminAddNotice(param) {  //admin添加公告
        return service({
            method: 'POST',
            url: '/webuser/admin/addNotice',
            data: param
        })
    },
    ApiAdminRemoveNotice(param) {  //admin删除公告
        return service({
            method: 'POST',
            url: '/webuser/admin/removeNotice',
            data: param
        })
    },
    ApiAdminGetGifts(param) {  //admin获取礼物
        return service({
            method: 'POST',
            url: '/webuser/admin/getGifts',
            data: param
        })
    },
    ApiAdminUploadGiftImg(formData) {
        return service.post('/webuser/admin/uploadGiftImg', formData)
    },
    ApiAdminAddGift(param) {  //admin上传礼物
        return service({
            method: 'POST',
            url: '/webuser/admin/addGift',
            data: param
        })
    },
    ApiAdminRemoveGift(param) {  //admin删除礼物
        return service({
            method: 'POST',
            url: '/webuser/admin/removeGift',
            data: param
        })
    },
    ApiAdminGetOrder(param) {  //admin获取order
        return service({
            method: 'POST',
            url: '/webuser/admin/getOrder',
            data: param
        })
    },
    ApiAdminGetUserInfo(param) {  //admin获取user
        return service({
            method: 'POST',
            url: '/webuser/admin/getUserInfo',
            data: param
        })
    },
    ApiAdminMonReg() {  //admin获取注册的用户
        return service({
            method: 'GET',
            url: '/webuser/admin/getMonReg',
        })
    },
    ApiRemoveBlog(param) {  //user删除blog
        return service({
            method: 'POST',
            url: '/webblog/removeBlog',
            data: param
        })
    },
    ApiGetGifts() {  //user获取礼物
        return service({
            method: 'GET',
            url: '/webuser/gifts',
        })
    },
    ApiGetBalance() {  //user获取余额
        return service({
            method: 'GET',
            url: '/webuser/balance/',
        })
    },
    ApiGetUserOder(id) {  //获取用户最近一周的消费
        return service({
            method: 'GET',
            url: `/webuser/userOrder/${id}`,
        })
    },
    ApiPostReward(param) {  //user进行打赏
        return service({
            method: 'POST',
            url: '/webuser/reward',
            data: param
        })
    },
    ApiConfirmPay(param) {  //用户进行充值
        return service({
            method: 'POST',
            url: '/webuser/pay/confirm',
            data: param
        })
    },
    ApiVetifyPay(param) {  //QR心跳检查
        return service({
            method: 'POST',
            url: '/webuser/pay/vertify',
            data: param
        })
    },
    ApiCompletePay(param) {  //完成充值
        return service({
            method: 'POST',
            url: '/webuser/pay/complete',
            data: param
        })
    },
    ApiWithdraw(param) {  //提现
        return service({
            method: 'POST',
            url: '/webuser/withdraw',
            data: param
        })
    },
    ApiGetIncome() {  //收益记录
        return service({
            method: 'GET',
            url: '/webuser/income',
        })
    },
    ApiGetUserInfo(id) {      //加载个人卡片
        return service({
            method: 'GET',
            url: `/webuser/user/${id}`,
        })
    },
    ApiGetUserFriends(id) {      //获取用户好友
        return service({
            method: 'GET',
            url: `/webuser/friends/${id}`,
        })
    },
    ApiGetEmailVertify(email) {
        return service({
            method: 'POST',
            url: '/webuser/emailVertify',
            data: {
                email
            }
        })
    },
    ApiGetBlogInfo({ userId, type, pageNum, pageSize, searchParam, toLike }) {     //加载主页blog
        return service({
            method: 'POST',
            url: '/webblog/blog',
            data: {
                userId,
                type,
                pageNum,
                pageSize,
                searchParam,
                toLike
            }
        })
    },
    ApiupdateUserInfo(userInfo) {
        return service({
            method: 'POST',
            url: '/webuser/updateInfo',
            data: {
                ...userInfo
            }
        })
    },

    ApiupdateUserAvatar(formData) {
        return service.post('/webuser/updateUserAvatar', formData)
    },
    ApiupdateUserBg(formData) {
        return service.post('/webuser/updateUserBg', formData)
    },
    ApiUploadBlogImg(formData) {
        return service.post('/webblog/uploadBlogImg', formData)
    },
    ApiUploadBlog(blogInfo) {
        return service({
            method: 'POST',
            url: '/webblog/uploadBlog',
            data: {
                ...blogInfo
            }
        })
    },
    ApiGetNotice() {
        return service.get('/webuser/notice')
    },
    //关注某人
    ApiFocusTo(id, status) {
        return service.post('/webuser/focus', {
            id,
            status
        })
    },
    //改变点赞状态
    ApiUpdateLike(param) {
        return service({
            method: 'POST',
            url: '/webblog/like',
            data: {
                ...param
            }
        })
    },
    //获取评论
    ApiGetComment(param) {
        return service({
            method: 'POST',
            url: '/webblog/comment',
            data: {
                ...param
            }
        })
    },
    //获取评论
    ApiPublishComment(commentValue, id) {
        return service.post('/webblog/tocomment', {
            commentValue,
            id
        })
    },
    //点击搜索框搜索热度高的标题
    ApiGetSearchTop() {
        return service.get('/webblog/searchtop')
    },
    //点击搜索框搜索热度高的标题
    ApiGetBlogById(id) {
        return service.get(`/webblog/blog/${id}`)
    },
    ApiGetUserFocusAndFans({ pageNum, pageSize, userId, focus }) {
        return service({
            method: 'POST',
            url: '/webuser/friend',
            data: {
                pageNum,
                pageSize,
                userId,
                focus
            }
        })
    },
    //获取未读消息数量
    ApiGetUnreadCount(id) {
        return service({
            method: 'POST',
            url: '/webuser/unread',
            data: {
                id
            }
        })
    },
    //
    ApiSearchUser({ searchParam, pageNum, pageSize }) {
        return service({
            method: 'POST',
            url: '/webuser/searchUser',
            data: {
                searchParam,
                pageNum,
                pageSize
            }
        })
    },
    ApiGetMyFocusBlog({ pageNum, pageSize, searchParam }) {
        return service({
            method: 'POST',
            url: '/webblog/myFocusBlog',
            data: {
                pageNum,
                pageSize,
                searchParam
            }
        })
    },
    ApiShareBlog(blogId, shareId) {
        return service({
            method: 'POST',
            url: '/webblog/shareBlog',
            data: {
                blogId,
                shareId
            }
        })
    },
    ApiFeedBack(param) {
        return service({
            method: 'POST',
            url: '/webuser/feedback',
            data: {
                param
            }
        })
    },

}