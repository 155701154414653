import axios from 'axios'
import { ElMessage } from 'element-plus'
import constants from '@/common/common.js';
import { ElLoading } from 'element-plus';

const service = axios.create({
    baseURL: constants.REQUEST_BASE_URL,
    timeout: 8000,
})
let loadingCount = 0; // 计数器
let loadingInstance;
service.interceptors.request.use(function (config) {
    if (loadingCount === 0) {
        loadingInstance = ElLoading.service({
            lock: true,
            text: '加载中...',
            background: 'rgba(0, 0, 0, 0.7)',
        });
    }
    loadingCount++;
    const token = sessionStorage.getItem("LOGIN_TOKEN")
    if (config.url.includes('login') || config.url.includes('registy') || config.url.includes('emailVertify') || config.url.includes('adminLogin')) {
        return config
    }
    if (token == null) {
        loadingCount--;
        if (loadingCount === 0) {
            loadingInstance?.close();
        }
        window.location.href = "/login";
        return ElMessage.error("您尚未登录!")
    }
    config.headers.set("token", token)
    return config

}, function (error) {
    loadingCount--;
    loadingInstance?.close();
    return Promise.reject(error);
});


service.interceptors.response.use((res) => {
    const { code, data } = res.data
    if (code == 200) {
        loadingCount--;
        loadingInstance?.close();

        return Promise.resolve(data)
    } else {
        if (res.config.url == '/webuser/pay/vertify') {
            loadingCount--;
            loadingInstance?.close();
            return Promise.reject()
        }
        if (code == 203 || code == 202) {
            sessionStorage.removeItem("LOGIN_TOKEN")
            window.location.href = "/login";
        }
        const NETWORK_ERROR = "网络异常"
        loadingCount--;
        loadingInstance?.close();
        ElMessage.error(data || NETWORK_ERROR)
        return Promise.reject(data)
    }
}, function (error) {
    loadingCount--;
    loadingInstance?.close();
    return Promise.reject(error);
})




export default service