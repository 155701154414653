<template>
  <router-view></router-view>
</template>
<script setup>
import {ref,onMounted,getCurrentInstance} from 'vue'
import {useStore} from '@/pinia/index'
// import {connectWs} from '@/util/func'
const {proxy} = getCurrentInstance()
const store = useStore()
const token = sessionStorage.getItem("LOGIN_TOKEN")
// var ws = ref(null)
// onMounted(() => {
//   if(store.introInfo.id!=''){  //已经登陆
//     let  count = connectWs(store,store.introInfo.id,"1").count
//     store.unreadCount = count
//   }
// })

</script>



<style lang="scss">
body{
    background-color:rgb(246, 246, 246)
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  li{
    list-style-type: none;
  }
}
</style>
