<template>
  <div class="bg">
    <el-card class="login-card" shadow="hover">
      <img :src="require('@/assets/svg/旺旺.svg')" />
      <br/>
      <br/>
      <br/>
      <!-- 登录表 -->
      <el-form
        autocomplete="off"
        ref="loginFormRef"
        :rules="loginRules"
        :model="logininfo"
        v-if="!adminLoginMethod && currPage"
      >
        <el-form-item prop="username">
          <el-input
            v-model="logininfo.username"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="用户名"
            prefix-icon="User"
          />
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model="logininfo.password"
            style="width: 300px; height: 45px"
            size="large"
            type="password"
            placeholder="密码"
            prefix-icon="Lock"
          />
        </el-form-item>

        <el-form-item prop="captcha">
          <div class="codesection">
            <el-input
              v-model="logininfo.captcha"
              class="codeipt"
              style="width: 180px; height: 45px"
              size="large"
              placeholder="验证码"
            />

            <div class="codeimg" @click="handlleClickCaptchaInfo">
              <img :src="captchaImg" alt="" />
            </div>
          </div>
        </el-form-item>
      </el-form>

      <!-- 注册表 -->
      <el-form
        autocomplete="off"
        ref="registyFormRef"
        :rules="registyRules"
        :model="registyInfo"
        v-else-if="!adminLoginMethod && !currPage"
        class="regForm"
      >
        <el-form-item prop="username"
          ><el-input
            v-model="registyInfo.username"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="用户名"
            prefix-icon="User"
        /></el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="registyInfo.password"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="密码"
            prefix-icon="Lock"
          />
        </el-form-item>
        <el-form-item prop="verifypassword">
          <el-input
            type="password"
            v-model="registyInfo.verifypassword"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="确认密码"
            prefix-icon="Lock"
          />
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            v-model="registyInfo.email"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="您的邮箱"
            prefix-icon="Message"
          />
        </el-form-item>
        <el-form-item prop="verty" class="getVertify">
          <div class="timerBtn">
            <el-input
              v-model="registyInfo.verty"
              style="width: 180px; height: 45px"
              size="large"
              placeholder="验证码"
              prefix-icon="Message"
            />
          </div>
          <div class="cooldown-button">
            <el-button :disabled="isDisabled" @click="getEmailBtn()">
              <template v-if="isDisabled">
                <el-icon class="countdown-icon"><Timer /></el-icon>
                {{ countdown }}秒后可用
              </template>
              <template v-else>
                <el-icon><Send /></el-icon>
                发送验证码
              </template>
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <!-- 管理员登录 -->
      <el-form
        autocomplete="off"
        ref="adminFormRef"
        :rules="adminRules"
        :model="adminInfo"
        v-else-if="adminLoginMethod"
        class="regForm"
      >
        <el-form-item prop="username"
          ><el-input
            v-model="adminInfo.username"
            style="width: 300px; height: 45px"
            size="large"
            placeholder="用户名"
            prefix-icon="User"
        /></el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="adminInfo.password"
            style="
              width: 300px;
              height: 45px;
              margin-top: 50px;
              margin-bottom: 50px;
            "
            size="large"
            placeholder="密码"
            prefix-icon="Lock"
          />
        </el-form-item>
      </el-form>

      <br />
      <el-button class="loginbtn" type="primary" round @click="submit">{{
        adminLoginMethod ? "登录" : currPage ? "登录" : "注册"
      }}</el-button>
      <div class="otheroption">
        <el-link
          ><el-text tag="b" @click="changePage" v-if="!adminLoginMethod">{{
            currPage ? "创建新账号" : "去登录"
          }}</el-text></el-link
        >
        <el-link
          ><el-text tag="b" @click="changeLoginMethod">{{
            adminLoginMethod ? "普通登录" : "管理员登录"
          }}</el-text></el-link
        >
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { throttle } from "lodash"; // 或者使用 throttle
import { useStore } from "@/pinia/index";
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  nextTick,
  computed,
  onUnmounted,
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { connectWs } from "@/util/func";
const countdown = ref(0);
const isDisabled = computed(() => countdown.value > 0);

const emailRex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const currPage = ref(true); //是否为登录页
const store = useStore();
const LOGIN_TOKEN = "LOGIN_TOKEN";
const router = useRouter();
const { proxy } = getCurrentInstance();
let adminLoginMethod = ref(false);
let captchaImg = ref("");
const loginFormRef = ref("");
const registyFormRef = ref("");
const adminFormRef = ref("");
let EmailButton = null;
onUnmounted(() => {
  if (EmailButton) {
    clearInterval(EmailButton);
  }
});
// 开始倒计时
const startCountdown = () => {
  countdown.value = 30;
  EmailButton = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(EmailButton);
    }
  }, 1000);
};

const logininfo = ref({
  username: "",
  password: "",
  randomString: "",
  captcha: "",
});
const registyInfo = ref({
  username: "",
  password: "",
  verifypassword: "",
  email: "",
  verty: "",
});
const adminInfo = ref({
  username: "",
  password: "",
});
const registyRules = ref({
  username: [
    { required: true, message: "请输入用户名", trigger: "blur" },
    { min: 8, max: 12, message: "请输入8到12位的用户名", trigger: "blur" },
  ],
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, max: 20, message: "请输入8到20位的密码", trigger: "blur" },
  ],
  verifypassword: [
    { required: true, message: "请输入验证码", trigger: "blur" },
    { min: 8, max: 20, message: "请输入8到20位的密码", trigger: "blur" },
  ],
  email: [
    { required: true, message: "请输入邮箱地址", trigger: "blur" },
    {
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "请输入有效的邮箱地址",
      trigger: "blur",
    },
  ],
  verty: [
    { required: true, message: "请输入验证码", trigger: "blur" },
    { min: 6, max: 6, message: "请输入6的验证码", trigger: "blur" },
  ],
});
const loginRules = ref({
  username: [
    { required: true, message: "请输入用户名", trigger: "blur" },
    { min: 8, max: 12, message: "请输入8到12位的用户名", trigger: "blur" },
  ],
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, max: 20, message: "请输入8到20位的密码", trigger: "blur" },
  ],
  captcha: [
    { required: true, message: "请输入验证码", trigger: "blur" },
    { min: 4, max: 4, message: "请输入完整4位验证码", trigger: "blur" },
  ],
});
const adminRules = ref({
  username: [
    { required: true, message: "请输入用户名", trigger: "blur" },
    { min: 8, max: 12, message: "请输入8到12位的用户名", trigger: "blur" },
  ],
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, max: 20, message: "请输入8到20位的密码", trigger: "blur" },
  ],
});
onMounted(() => {
  store.$reset();
  router.push("/login");
  sessionStorage.removeItem("LOGIN_TOKEN");
  currPage.value ? router.push("/login") : router.push("/registy");
  getCaptchaInfo();
});
//切换登录方式
const changeLoginMethod = () => {
  adminLoginMethod.value = !adminLoginMethod.value;
};
//节流
const handlleClickCaptchaInfo = throttle(function () {
  getCaptchaInfo();
}, 2000);

//请求验证码
const getCaptchaInfo = async () => {
  if (!currPage.value) return; //注册页面返回
  const res = await proxy.$api.ApigetCaptchaInfo();
  logininfo.value.randomString = res.uuid;
  captchaImg.value = res.base64;
};
const submit = async () => {
  if (adminLoginMethod.value) {
    //管理员登录
    adminFormRef.value.validate((valid) => {
      if (!valid) {
        return ElMessage.error("请完成信息输入");
      } else {
        adminLogin(adminInfo.value);
      }
    });
  } else if (!currPage.value) {
    //注册
    registyFormRef.value.validate((vali) => {
      if (!vali) {
        return ElMessage.error("请完成信息输入");
      } else {
        if (registyInfo.value.password != registyInfo.value.verifypassword) {
          return ElMessage.error("密码不一致");
        } else {
          registy(registyInfo.value);
        }
      }
    });
  } else {
    //登陆
    loginFormRef.value.validate((valid) => {
      if (!valid) {
        return ElMessage.error("请完成信息输入");
      } else {
        login(logininfo.value);
      }
    });
  }
};
const registy = async (registyInfo) => {
  try {
    const res = await proxy.$api.gotoRegistyApi(registyInfo);
    router.push("/login");
    currPage.value = true;
    ElMessage.success(res);
  } catch (error) {}
};
const login = async (logininfo) => {
  try {
    const res = await proxy.$api.ApiGotoLogin(logininfo);
    ElMessage.success("登陆成功");
    sessionStorage.setItem(LOGIN_TOKEN, res);
    router.push("/");
  } catch (error) {
    getCaptchaInfo();
  }
};
const adminLogin = async (logininfo) => {
  try {
    const res = await proxy.$api.ApiAdminLogin(logininfo);
    ElMessage.success("登陆成功");
    sessionStorage.setItem(LOGIN_TOKEN, res);
    router.push("/admin");
  } catch (error) {}
};
const changePage = () => {
  currPage.value = !currPage.value;
  currPage.value ? router.push("/login") : router.push("/registy");
};
const getEmailBtn = async () => {
  if (isDisabled.value) {
    return ElMessage.warning("冷却中");
  }
  if (emailRex.test(registyInfo.value.email)) {
    const res = await proxy.$api.ApiGetEmailVertify(registyInfo.value.email);
    startCountdown();
  } else {
    ElMessage.error("邮箱格式不正确");
  }
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100vh;
  background-image: url("@/assets/loginbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  .login-card {
    .el-form-item {
      margin: 0 auto 40px;
      width: 300px;
    }
    .regForm {
      .el-form-item {
        margin: 0 auto 15px;
        width: 300px;
        .getVerty {
          margin-left: 10px;
          .timerBtn {
            display: flex;
            justify-content: space-between;
          }
          .cooldown-button {
            display: inline-block;
          }

          .el-button {
            width: 200px;
            float: right;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
          }

          .countdown-icon {
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          :deep(.el-button.is-disabled) {
            cursor: not-allowed;
            opacity: 0.8;
            background-color: #a0cfff;
            border-color: #a0cfff;
            color: #fff;
          }
        }
      }
    }
    text-align: center;
    border: 0;
    text-align: center;
    position: absolute;
    width: 480px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    border-radius: 20px;
    span {
      display: block;
      margin: 20px 0 20px 0;
      font-weight: 700;
      font-size: 80px;
      color: black;
      opacity: 1;
    }
    :deep(.el-input__wrapper) {
      border-radius: 20px;
      opacity: 0.6;
    }
    :deep(.el-input__prefix) {
      color: #000000;
    }
    :deep(.el-input__inner) {
      color: #000000;
    }
    .el-input {
      font-weight: 500;
      font-size: 18px;
    }
    .codesection {
      display: flex;
      justify-content: space-between;
      width: 300px;
      margin: 0 auto;
      .codeimg {
        cursor: pointer;
        width: 100px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .loginbtn {
      margin-top: -20px;
      width: 300px;
      height: 45px;
      font-size: 20px;
      font-weight: 500;
    }
    .otheroption {
      margin: 0 auto;
      width: 300px;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 15px;
      .el-text {
        color: #000000;
      }
    }
  }
}
</style>