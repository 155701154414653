<template>
  <el-card>
    <el-popover
      popper-class="el-popover-self"
      placement="right"
      trigger="hover"
      :show-arrow="false"
      :width="350"
      show-after="100"
    >
      <template #reference>
        <div class="avatar">
          <el-avatar :size="50" :src="blogInfoItem.avatar" />
        </div>
      </template>
      <div class="blogPop">
        <div class="topArea">
          <el-button class="popOtherBtn">···</el-button>
          <img :src="blogInfoItem.bg" alt="TA的背景图片" />
          <div class="popAvator">
            <img :src="blogInfoItem.avatar" alt="TA的头像" />
          </div>
          <span class="popName">{{ blogInfoItem.nickname }}</span>
        </div>
        <div class="bottomArea">
          <div class="popFocusAndFan">
            <span>关注&nbsp;{{ blogInfoItem.focusCount }}</span>
            <span>|</span>
            <span>粉丝&nbsp;{{ blogInfoItem.fansCount }}</span>
          </div>
          <div class="popBtn" v-if="blogInfoItem.onFocus != 0">
            <el-button
              round
              @click="router.push(`/personpg/${blogInfoItem.userId}`)"
              >主页</el-button
            >
            <el-button round @click="goSixin(blogInfoItem)">私信</el-button>
          </div>
          <div class="popBtn" v-else>
            <el-button
              round
              @click="router.push(`/personpg/${blogInfoItem.userId}`)"
              >查看主页</el-button
            >
          </div>
        </div>
      </div>
    </el-popover>
    <div class="info">
      <el-text class="name" tag="b">{{ blogInfoItem.nickname }}</el-text>
      <el-check-tag
        effect="light"
        round
        :checked="!blogInfoItem.onFocus == 1"
        @change="gotoFocus(blogInfoItem.userId, blogInfoItem.onFocus)"
        v-if="blogInfoItem.userId != store.introInfo.id"
        :style="{
          backgroundColor:
            blogInfoItem.onFocus == 2 ? 'rgb(240,92,17)' : 'rgb(233,233,233)',
        }"
      >
        {{ blogInfoItem.onFocus == 1 ? "√已关注" : "+关注" }}
      </el-check-tag>
      <br />
      <el-text>{{ blogInfoItem.publishTime }}</el-text>
    </div>

    <el-tooltip content="删除" placement="top">
      <el-icon
        color="red"
        size="2em"
        style="
          cursor: pointer;
          float: right;
          margin-left: 20px;
          margin-right: 50px;
        "
        v-if="store.introInfo.id == blogInfoItem.userId"
        @click="handleDelete"
        ><DeleteFilled
      /></el-icon>
    </el-tooltip>

    <!-- 打赏按钮 -->
    <el-popover
      v-model:visible="giftPopVisable"
      trigger="click"
      :width="400"
      placement="top"
    >
      <template #reference>
        <el-button type="" link style="float: right" @click="getGiftList">
          <el-tooltip content="打赏" effect="customized" placement="top">
            <el-icon
              color="purple"
              size="2em"
              style="cursor: pointer; float: right"
              v-if="store.introInfo.id != blogInfoItem.userId"
              ><Shop
            /></el-icon>
          </el-tooltip>
        </el-button>
      </template>
      <div style="float: right">
        余额:&nbsp;<span class="highlight">{{ balance }}</span
        >&nbsp;<span style="font-size: 0.8em; font-weight: 600">茄特币</span>
      </div>

      <!-- 礼物列表 -->
      <div class="gift-container">
        <h3 class="gift-title">请选择打赏礼物</h3>
        <div class="gift-grid">
          <div
            v-for="gift in giftList"
            :key="gift.id"
            class="gift-item"
            :class="{ active: selectedGift?.id === gift.id }"
            @click="handleSelectGift(gift)"
          >
            <img :src="gift.img" :alt="gift.name" class="gift-icon" />
            <div class="gift-name">{{ gift.name }}</div>
            <div class="gift-price">{{ gift.price }}茄币</div>
          </div>
          <el-empty
            style="width: 200%"
            v-if="giftList == ''"
            description="暂无礼物,请联系管理员"
          ></el-empty>
        </div>

        <!-- 打赏按钮 -->
        <div class="reward-footer">
          <el-button
            type="danger"
            :disabled="!selectedGift"
            @click="handleReward"
          >
            立即打赏
          </el-button>
        </div>
      </div>
    </el-popover>

    <!-- 确认对话框 -->
    <el-dialog
      v-model="giftRewardVisable"
      title="确认打赏"
      width="360px"
      center
      :show-close="false"
    >
      <div class="confirm-content">
        <img
          :src="selectedGift?.img"
          :alt="selectedGift?.name"
          class="confirm-icon"
        />
        <p class="confirm-text">
          确定要打赏
          <span class="highlight">{{ selectedGift?.name }}</span>
          <span>给</span>
          <span class="highlight">{{ blogInfoItem.nickname }}</span>
          吗？
        </p>
        <p class="confirm-price">
          价格：<span class="highlight">{{ selectedGift?.price }}茄特币</span>
          <span style="font-size: 0.8em; font-weight: 500"
            >(约{{ (selectedGift?.price / 100).toFixed(2) }}￥)</span
          >
        </p>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleCancel">取消</el-button>
          <el-button type="danger" :loading="loading" @click="rewardConfirm">
            确认打赏
          </el-button>
        </div>
      </template>
    </el-dialog>
    <div class="content">
      <!-- 标题 -->
      <div>
        <el-text class="content-title" size="large" tag="b">{{
          blogInfoItem.title
        }}</el-text>
        <el-tag type="primary" v-if="blogInfoItem.tags[0]">{{
          blogInfoItem.tags[0]
        }}</el-tag>
        <el-tag type="success" v-if="blogInfoItem.tags[1]">{{
          blogInfoItem.tags[1]
        }}</el-tag>
        <el-tag type="info" v-if="blogInfoItem.tags[2]">{{
          blogInfoItem.tags[2]
        }}</el-tag>
      </div>

      <!-- 内容 -->
      <div class="main-content">
        <el-text size="large" id="blogContent">
          {{ blogInfoItem.content }} </el-text
        ><br />
        <div class="main-imgs" v-if="blogInfoItem.media != ''">
          <el-image
            v-for="(item, index) in blogInfoItem.media"
            :key="index"
            style="width: 130px; height: 130px"
            :src="item"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="blogInfoItem.media"
            fit="cover"
            :initial-index="0"
          />
        </div>
      </div>
      <div>
        <el-tag type="info" class="iptag"
          >ip:{{ blogInfoItem.publishIp }}</el-tag
        >
      </div>
      <!-- 互动 -->
      <div class="interaction">
        <div @click="gotoLike">
          <img
          class="interaction_svg"
            v-show="!blogInfoItem.onLike"
            :src="require('@/assets/svg/未点赞.svg')"
          />
          <img
          class="interaction_svg"
            v-show="blogInfoItem.onLike"
            :src="require('@/assets/svg/已点赞.svg')"
          />
          <span>{{ blogInfoItem.likeCount }}</span>
        </div>
        <div @click="commentShow">
          <img
          class="interaction_svg"
            :src="require('@/assets/svg/评论.svg')"
          />
          <span>{{ blogInfoItem.commentCount }}</span>
        </div>

        <div class="popover-container">
          <el-popover
            :visible="visible"
            trigger="click"
            placement="bottom-start"
            width="300"
            @hide="visible = false"
          >
            <template #reference>
              <div @click="prepareToShare">
                <img
          class="interaction_svg"
                  :src="require('@/assets/svg/分享.svg')"
                />
                <span>{{ blogInfoItem.repostCount }}</span>
              </div>
            </template>

            <!-- 用户列表 -->
            <div class="user-list">
              <div
                v-for="user in chatUserList"
                :key="user.id"
                class="user-item"
              >
                <div class="user-info">
                  <el-avatar :size="40" :src="user.avatar" />
                  <span class="username">{{ user.nickname }}</span>
                </div>
                <el-button
                  :type="user.isShare == 1 ? 'info' : 'primary'"
                  round
                  :disabled="user.isShare == 1"
                  size="small"
                  @click="handleShare(user)"
                  >{{ user.isShare == 1 ? "√已分享" : "分享" }}</el-button
                >
              </div>

              <!-- 加载更多 -->
              <div v-if="chatUserList.length" class="load-more"></div>

              <!-- 空状态 -->
              <el-empty v-else description="暂无用户" :image-size="60" />
            </div>
          </el-popover>
        </div>
      </div>

      <!-- 评论区 -->
      <div v-if="commentVisable">
        <div class="discuss">
          <div class="godis">
            <el-input
              v-model="commentValue"
              ref="commentRef"
              style="width: 530px"
              placeholder="留下属于你的评论吧~"
              prefix-icon="EditPen"
            />
            <el-icon class="publishComment" @click="publishComment"
              ><Promotion
            /></el-icon>
          </div>
        </div>
        <el-scrollbar max-height="400px">
          <Comment
            v-for="(item, index) in comments"
            :key="index"
            :comment="item"
          >
          </Comment>
          <div
            class="loadingMoreCommentBtn"
            @click="loadingMoreComment"
            :style="{ color: loadingMoreCommentBtn ? 'orange' : 'grey' }"
          >
            {{ loadingMoreCommentBtn ? "查看更多" : "暂无更多评论" }}
          </div>
        </el-scrollbar>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import {
  ref,
  defineEmits,
  defineProps,
  getCurrentInstance,
  onMounted,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/pinia/index.js";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import Comment from "@/components/Comment.vue";
const chatUserList = ref([]);
const shareVisable = ref(false);
const emit = defineEmits(["shareBlog"]);
const msgInfo = ref({
  sendId: "",
  recId: "0",
  type: 1,
  content: "",
});
// 处理分享按钮点击
const handleShare = async (user) => {
  if (user.id == "000000") {
    return ElMessage.warning("AI聊天开发中");
  }
  //发送请求
  await proxy.$api.ApiShareBlog(blogInfoItem.id, user.id);
  emit("shareBlog", {
    nickname: user.nickname,
    sendId: store.introInfo.id,
    recId: user.id,
    type: 1,
    content: blogInfoItem.id,
  });
  user.isShare = 1;
  ElMessage.success(`分享用户：${user.nickname}`);
};
const router = useRouter();
const loadingMoreCommentBtn = ref(true);
const commentValue = ref("");
const commentParam = ref({
  pageNum: 1,
  pageSize: 3,
  blogId: blogInfoItem.id,
});
// 控制弹出层显示
const giftPopVisable = ref(false);
const giftRewardVisable = ref(false);
const loading = ref(false);
const selectedGift = ref(null);
const balance = ref(null);
// 礼物列表数据
const giftList = ref([]);
const getGiftList = async () => {
  const res = await proxy.$api.ApiGetGifts();
  giftList.value = res.gifts;
  balance.value = res.balance;
};
// 选择礼物
const handleSelectGift = (gift) => {
  selectedGift.value = gift;
};

// 点击打赏按钮
const handleReward = () => {
  if (!selectedGift.value) return;
  giftRewardVisable.value = true;
  giftPopVisable.value = false;
};

// 取消打赏
const handleCancel = () => {
  giftRewardVisable.value = false;
  selectedGift.value = null;
};

// 确认打赏
const rewardConfirm = async () => {
  if (!selectedGift.value) return;

  loading.value = true;
  try {
    // 这里添加打赏请求
    await proxy.$api.ApiPostReward({
      fromId: store.introInfo.id,
      toId: blogInfoItem.userId,
      giftId: selectedGift.value.id,
    });

    ElMessage.success("打赏成功！");
    giftRewardVisable.value = false;
    selectedGift.value = null;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};
const comments = ref([]);
const { proxy } = getCurrentInstance();
const store = useStore();
const commentRef = ref(null);
const { blogInfoItem } = defineProps(["blogInfoItem"]);
const commentVisable = ref(false);
const gotoFocus = async (id, status) => {
  // 发送请求
  const res = await proxy.$api.ApiFocusTo(id, status);
  blogInfoItem.onFocus = status == 1 ? 2 : 1;
  ElMessage.success(res);
};
const gotoLike = async () => {
  //发送请求
  const status = blogInfoItem.onLike;
  const param = {
    status: status,
    blogId: blogInfoItem.id,
  };
  const res = await proxy.$api.ApiUpdateLike(param);
  //请求成功改变点赞状态
  if (status == true) {
    blogInfoItem.onLike = false;
    blogInfoItem.likeCount--;
  } else {
    blogInfoItem.onLike = true;
    blogInfoItem.likeCount++;
  }
};
//点开评论
const prepareToShare = async () => {
  shareVisable.value = true;
  const res = await proxy.$api.ApiGetUserFriends(store.introInfo.id);
  chatUserList.value.push(...res);
};
//获取评论的请求
const getComment = async () => {
  const res = await proxy.$api.ApiGetComment(commentParam.value);
  commentParam.value.pageNum++;
  comments.value.push(...res);
  if (res.length < 3) {
    loadingMoreCommentBtn.value = false;
  }
};
const loadingMoreComment = () => {
  loadingMoreCommentBtn.value ? getComment() : ElMessage.error("暂无更多评论");
};
//展开评论区
const commentShow = () => {
  commentVisable.value = true;
  getComment();
  nextTick(() => {
    commentRef.value.focus();
  });
};
//发表评论
const publishComment = async () => {
  if (commentValue.value.length >= 50) {
    return ElMessage.error("评论内容不能超过50字");
  }
  if (commentValue.value.length <= 0) {
    return ElMessage.error("评论内容不能为空");
  }
  const res = await proxy.$api.ApiPublishComment(
    commentValue.value,
    blogInfoItem.id
  );
  ElMessage.success(res);
  comments.value = [];
  commentValue.value = "";
  commentParam.value.pageNum = 1;
  loadingMoreCommentBtn.value = true;
  blogInfoItem.commentCount++;
  getComment();
};
const goSixin = (item) => {
  let index = -1;
  const res = store.chatUserList.find((a) => {
    //找到了
    index++;
    if (a.id == item.userId) {
      if (index == 0) {
        return true;
      }
      let tmpobj = {};
      tmpobj = store.chatUserList[0];
      store.chatUserList[0] = store.chatUserList[index];
      store.chatUserList[index] = tmpobj;
      return true;
    }
  });
  if (res == undefined) {
    //没找到
    store.chatUserList.unshift({
      id: item.userId,
      avatar: item.avatar,
      intro: item.intro,
      nickname: item.nickname,
      chatRecord: [],
    });
  }
  router.push("/chat");
};
const handleDelete = () => {
  ElMessageBox.confirm(
    "确定要删除该博客吗？此操作不可恢复！",

    "警告",

    {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  )
    .then(async () => {
      try {
        await proxy.$api.ApiRemoveBlog({
          id: blogInfoItem.id,
          type: blogInfoItem.type,
          userId: blogInfoItem.userId,
        });
        ElMessage.success("删除成功");
      } catch (error) {
        ElMessage.error("删除失败");
      }
    })
    .catch(() => {});
};
</script>
<style lang="scss">
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
.el-popover-self {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.user-list {
  max-height: 300px;
  overflow-y: auto;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s;
}

.user-item:last-child {
  border-bottom: none;
}

.user-item:hover {
  background-color: #f5f7fa;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.username {
  font-size: 14px;
  color: #303133;
}

.load-more {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #eee;
}

/* 自定义滚动条样式 */
.user-list::-webkit-scrollbar {
  width: 6px;
}

.user-list::-webkit-scrollbar-thumb {
  background-color: #909399;
  border-radius: 3px;
}

.user-list::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

:deep(.el-popover.el-popper) {
  padding: 0;
  overflow: hidden;
}
.blogPop {
  padding: 0;
  height: 200px;
  .topArea {
    position: relative;
    width: 100%;
    height: 55%;
    img {
      width: 100%;
      height: 100%;
    }
    .popAvator {
      position: absolute;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      top: 5%;
      left: 50%;
      transform: translate(-50%, 0%);
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .popName {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      color: black;
      font-weight: 700;
    }
    .popOtherBtn {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 5%;
      right: 5%;
      border-radius: 50%;
      font-weight: 600;
    }
  }
  .bottomArea {
    width: 100%;
    height: 45%;
    .popFocusAndFan {
      display: flex;
      justify-content: space-around;
      line-height: 30px;
      width: 210px;
      height: 30px;
      margin: 5px auto;
      color: black;
    }
    .popBtn {
      width: 60%;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
    }
  }
}
.avatar {
  float: left;
  vertical-align: middle;
}
.info {
  display: inline-block;
  margin-left: 20px;
  .name {
    font-size: 18px;
    color: black;
  }
  .el-check-tag {
    border-radius: 15px;
    margin-left: 15px;
    font-size: 12px;
  }
}
.attention {
  float: right;
  .otherIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    .el-icon {
      color: black;
      width: 40px;
      height: 40px;
    }
  }
  .otherIcon:hover {
    background-color: rgb(227, 220, 220);
  }
}
.content {
  padding: 20px 70px;
  .content-title {
    font-size: 20px;
    color: black;
  }
  .el-tag {
    margin-left: 10px;
  }
  .main-content {
    margin-top: 20px;
    color: rgb(51, 51, 51);
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    .main-imgs {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .el-image {
        margin: 0 5px 10px 0;
      }
    }
  }
  .iptag {
    width: 100px;
    height: 30px;
    margin-left: -2px;
    font-size: 20px;
  }
  .interaction {
    width: 300px;
    display: flex;
    justify-content: space-around;
    float: right;
    margin-top: 20px;
    i {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: middle;
      color: red;
    }
    div:hover {
      color: red;
      cursor: pointer;
    }
  }
  .discuss {
    .godis {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 70px;
      height: 45px;
      background-color: rgb(240, 240, 240);
      :deep(.el-input__wrapper) {
        border-radius: 30px;
        .publishComment {
          cursor: pointer;
        }
      }
    }
  }
  .loadingMoreCommentBtn {
    height: 2em;
    font-size: 1.3em;
    line-height: 2em;
    width: 100%;
    cursor: pointer;
    text-align: center;
    background-color: rgb(250, 250, 250);
  }
}
.gift-container {
  padding: 16px;
}

.gift-title {
  margin: 0 0 16px;
  text-align: center;
  color: #303133;
  font-size: 16px;
}

.gift-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 20px;
}

.gift-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.gift-item:hover {
  border-color: #ff4d4f;
  transform: translateY(-2px);
}

.gift-item.active {
  border-color: #ff4d4f;
  background-color: #fff1f0;
}

.gift-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.gift-name {
  font-size: 14px;
  color: #303133;
  margin-bottom: 4px;
}

.gift-price {
  font-size: 12px;
  color: #ff4d4f;
}

.reward-footer {
  text-align: center;
}

.confirm-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.confirm-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
}

.confirm-text {
  font-size: 16px;
  color: #606266;
  margin-bottom: 8px;
}

.confirm-price {
  font-size: 14px;
  color: #606266;
}

.highlight {
  color: #ff4d4f;
  font-weight: bold;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  gap: 16px;
}

:deep(.el-popover.el-popper) {
  padding: 0;
  overflow: hidden;
}
.interaction_svg{
  vertical-align: middle;
  margin-right: 5px;
}
</style>