<template>
  <Header class="header" />

  <div class="bg">
    <img src="@/assets/bg.jpg" alt="" />
    <!-- 搜索 -->
    <div class="searchArea">
      <div class="searchAreaTop">
        <el-input
          v-model="searchTopValue"
          @focus="searchTop"
          @blur="searchTopShow = false"
          style="width: 240px"
          placeholder="请输入感兴趣的内容"
          @keyup.enter="toSearch"
          prefix-icon="Search"
        />
        <!-- 下拉列表 -->
        <ul v-if="searchTopShow" class="dropdown-list">
          <li
            v-for="(item, index) in searchTopList"
            :key="index"
            @mousedown="selectSearchTopItem(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <el-button type="danger" round @click="toSearch">搜索</el-button>
    </div>
  </div>

  <div class="content">
    <Intro class="left-card" :introInfo="introInfo">
      <template v-slot:btn>
        <el-button
          class="goPagebtn"
          v-if="token"
          @click="router.push(`/personpg/${store.introInfo.id}`)"
          >查看主页</el-button
        >
        <el-button
          class="tologinbtn"
          type="primary"
          round
          size="large"
          v-else
          @click="router.push('/login')"
          >立即登录</el-button
        >
      </template>
      <template v-slot:interact>
        <div class="interact" v-if="token">
          <div @click="router.push(`/personpg/${store.introInfo.id}/focus`)">
            <span class="interact-name">关注</span
            ><img :src="require('@/assets/svg/我的关注.svg')" /><span
              class="interact-num"
              >{{ introInfo.focusCount }}</span
            >
          </div>
          <div @click="router.push(`/personpg/${store.introInfo.id}/fans`)">
            <span class="interact-name">粉丝</span
            ><img :src="require('@/assets/svg/粉丝趴.svg')" /><span
              class="interact-num"
              >{{ introInfo.fansCount }}</span
            >
          </div>
        </div>
        <div class="logininfo" v-else>
          <el-text type="info" size="large" tag="b"
            >还没有<el-text type="info" size="large" tag="ins">茄特</el-text
            >?</el-text
          >
          &nbsp;
          <el-text type="warning" size="large" tag="b">立即注册!</el-text>
        </div>
      </template>
    </Intro>
    <div class="main-content">
      <el-card class="middle-card classify">
        <el-menu
          mode="horizontal"
          style="max-width: 1400px"
          ellipsis
          default-active="1"
        >
          <el-menu-item index="1" @click="changeTags(1)"
            ><el-text tag="b">最新发布</el-text></el-menu-item
          >
          <el-menu-item index="2" @click="changeTags(2)"
            ><el-text tag="b">最多喜欢</el-text></el-menu-item
          >
          <el-menu-item index="3" @click="changeTags(3)"
            ><el-text tag="b">最多评论</el-text></el-menu-item
          >
          <el-menu-item index="4" @click="changeTags(4)"
            ><el-text tag="b">我的关注</el-text></el-menu-item
          >
        </el-menu>
        <el-button
          type="danger"
          class="publish"
          v-if="token"
          @click="publishBlogVisable = true"
          >发布+</el-button
        >
      </el-card>

      <ul
        class="middle-card"
        v-infinite-scroll="getBlogInfo"
        infinite-scroll-immediate
        :infinite-scroll-disabled="loadingMoreDisabled"
        infinite-scroll-delay="100"
      >
        <li v-for="item in blogInfo" :key="item.id">
          <Blog
            class="work-card"
            @share-blog="handleShare"
            :blogInfoItem="item"
          ></Blog>
        </li>
        <div class="disloading" v-show="loadingMoreDisabled">
          暂时无更多内容~
        </div>
      </ul>
    </div>

    <el-card class="right-card">
      <h2>公告栏</h2>
      <el-timeline style="max-width: 600px">
        <el-timeline-item
          v-for="item in notice"
          :key="item.id"
          :timestamp="item.createTime"
        >
          {{ item.content }}
        </el-timeline-item>
      </el-timeline>
      <!-- 空状态 -->
      <el-empty
        v-if="notice.length == 0"
        description="暂无公告"
        :image-size="120"
      />
    </el-card>
  </div>

  <!-- 发布博客的弹出 -->
  <el-dialog v-model="publishBlogVisable" title="发布文章" width="800">
    <el-form :model="publishBlogForm" ref="blogFormRule" :rules="blogFormRules">
      <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
        <el-input
          v-model="publishBlogForm.title"
          autocomplete="off"
          maxlength="15"
        />
      </el-form-item>
      <el-form-item label="标签" :label-width="formLabelWidth">
        <div class="flex gap-2">
          <el-tag
            v-for="tag in blogTags"
            :key="tag"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="InputRef"
            v-model="inputValue"
            class="w-20"
            size="small"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          />
          <el-button
            v-else
            v-show="outofTag"
            class="button-new-tag"
            size="small"
            @click="showInput"
          >
            + New Tag
          </el-button>
        </div>
      </el-form-item>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Emoji @select="addEmoji"></Emoji>
      <el-form-item
        label="主要内容"
        :label-width="formLabelWidth"
        prop="content"
      >
        <el-input
          v-model="publishBlogForm.content"
          :autosize="{ minRows: 6, maxRows: 20 }"
          type="textarea"
          placeholder="请输入您的内容"
        />
      </el-form-item>

      <el-form-item>
        <el-upload
          action=""
          v-model:file-list="fileList"
          :on-change="handleBlogIMGError"
          accept="image/jpg,image/jpeg,image/png,image/webp"
          :on-success="handleSuccess"
          :limit="6"
          :on-exceed="handleExceed"
          list-type="picture-card"
          :auto-upload="false"
          :before-upload="handleBeforeUpload"
        >
          <el-icon><Plus /></el-icon>

          <template #file="{ file }">
            <div>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <el-icon><Download /></el-icon>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>

        <el-dialog v-model="dialogVisible">
          <img w-full :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="publishBlogVisable = false">取消</el-button>
        <el-button type="primary" @click="uploadBlogInfo"> 发布 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { useStore } from "@/pinia/index.js";
import { onMounted, ref, getCurrentInstance, nextTick } from "vue";
import { useRouter } from "vue-router";
import Header from "../components/Header";
import Intro from "../components/Intro.vue";
import Blog from "../components/Blog.vue";
import { ElMessage, ElMessageBox, UploadUserFile } from "element-plus";
import Emoji from "@/components/Emoji.vue";
import {
  timeDifference,
  splitData,
  connectWs,
  getCurrentTimeFormatted,
  getCurrentDateTime,
} from "@/util/func";
import axios from "axios";
const searchTopShow = ref(false);
const uploadedImages = ref([]); // 存储已上传的图片地址
const dialogImageUrl = ref(""); //存储预览图片的 URL
const dialogVisible = ref(false); // // 控制预览对话框的显示
const disabled = ref(false);
const blogFormRule = ref(null);
const publishBlogVisable = ref(false);
const inputVisible = ref(false);
const inputValue = ref("");
const token = ref(sessionStorage.getItem("LOGIN_TOKEN") != null);
const router = useRouter();
const InputRef = ref(null);
const outofTag = ref(true);
const loadingMoreDisabled = ref(false);
const notice = ref([]);
const blogTags = ref([]);
const fileList = ref([]);
const searchTopList = ref(null);
const searchTopValue = ref(""); //搜索框内容
let ws = null;
const blogFormRules = ref({
  title: [
    { required: true, message: "请输入标题", trigger: "blur" },
    { min: 3, max: 15, message: "标题长度为3-15个字符", trigger: "blur" },
  ],
  content: [
    { required: true, message: "请输入内容", trigger: "blur" },
    { min: 10, max: 200, message: "标题长度为10-200个字符", trigger: "blur" },
  ],
});
const publishBlogForm = ref({
  title: "",
  tags: "",
  content: "",
  media: "",
});
const handleShare = (msg) => {
  const tmp = JSON.parse(JSON.stringify(msg));
  tmp.sendId = store.introInfo.id;
  tmp.time = getCurrentTimeFormatted();
  ws.send(JSON.stringify(tmp));
  const res = store.chatUserList.find((a) => {
    if (a.id == msg.recId) {
      a.chatRecord.push(tmp);
      return true;
    }
  });
  let obj = {
    avatar: store.introInfo.avatar,
    id: tmp.recId,
    nickname: tmp.nickname,
    chatRecord: [
      {
        content: tmp.content,
        recId: tmp.recId,
        sendId: tmp.sendId,
        type: tmp.type,
        publishTime: Date.now(),
      },
    ],
  };
  if (res == undefined) {
    store.chatUserList.unshift(obj);
  }
};
const addEmoji = (emoji) => {
  publishBlogForm.value.content += emoji;
};
const changeTags = (param) => {
  if (blogSearch.value.type == param) {
    return;
  } else if (param == 4) {
    blogSearch.value.type = 4;
    loadingMoreDisabled.value = false;
    blogSearch.value.pageNum = 1;
    blogInfo.value = [];
    getMyFocusBlogInfo();
  } else {
    blogSearch.value.type = param;
    blogSearch.value.pageNum = 1;
    blogInfo.value = [];
    loadingMoreDisabled.value = false;
    getBlogInfo();
  }
};
const introInfo = ref({
  avatar: "",
  id: "",
  nickname: "未登录66",
  introduce: "请先登录",
  focusCount: "xxx",
  fanCount: "xxx",
});
const store = useStore();
const { proxy } = getCurrentInstance();
const blogInfo = ref([]);
const blogSearch = ref({
  userId: "",
  type: 1,
  pageNum: 1,
  pageSize: 3,
  searchParam: "",
});
onMounted(async () => {
  //未登录
  getMyInfo();
  getNotice();
  getBlogInfo();
});
const getBlogInfo = async () => {
  const res = await proxy.$api.ApiGetBlogInfo(blogSearch.value);
  if (!res || res == null || res.length < 3) {
    loadingMoreDisabled.value = true;
  }
  res.forEach((item) => {
    if (store.introInfo.id == item.userId) {
      item.onFocus = 0;
    } else if (item.onFocus == null) {
      item.onFocus = 2;
    } else {
      item.onFocus = 1;
    }
    item.onLike = item.onLike == null ? false : true;
    item.tags = splitData(item.tags);
    item.media = splitData(item.media);
    item.publishTime = timeDifference(item.publishTime);
  });
  blogInfo.value.push(...res);
  blogSearch.value.pageNum++;
};
const getMyFocusBlogInfo = async () => {
  const res = await proxy.$api.ApiGetMyFocusBlog(blogSearch.value);
  if (!res || res == null || res.length < 3) {
    loadingMoreDisabled.value = true;
  }
  res.forEach((item) => {
    if (store.introInfo.id == item.userId) {
      item.onFocus = 0;
    } else if (item.onFocus == null) {
      item.onFocus = 2;
    } else {
      item.onFocus = 1;
    }
    item.onLike = item.onLike == null ? false : true;
    item.tags = splitData(item.tags);
    item.media = splitData(item.media);
    item.publishTime = timeDifference(item.publishTime);
  });
  blogInfo.value.push(...res);
  blogSearch.value.pageNum++;
};
const getMyInfo = async () => {
  try {
    const res = await proxy.$api.ApiGetUserInfo(-1);
    store.introInfo = res;
    console.log("tttttttttttt", store.introInfo);
    introInfo.value = res;
    ws = connectWs(store, introInfo.value.id, "1");
    const res1 = await proxy.$api.ApiGetUnreadCount(res.id);
    store.unreadCount = res1;
  } catch (error) {}
};
const getNotice = async () => {
  const res = await proxy.$api.ApiGetNotice();
  notice.value.push(...res);
};
const handleClose = (tag) => {
  blogTags.value.splice(this.dynamicTags.indexOf(tag), 1);
  if (blogTags.value.length < 3) {
    outofTag.value = true;
  }
};
const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    InputRef.value.input.focus();
  });
};
const handleInputConfirm = () => {
  if (inputValue.value.includes(",")) {
    return ElMessage.error("标签不能包含逗号");
  }
  if (inputValue.value) {
    blogTags.value.push(inputValue.value);
  }
  inputVisible.value = false;
  inputValue.value = "";
  if (blogTags.value.length >= 3) {
    outofTag.value = false;
  }
};
// 处理文件超出限制
const handleExceed = () => {
  ElMessage.error("最多选择六张图片");
};
//校验
const handleBlogIMGError = (file) => {
  const isJPGorPNG =
    file.raw.type === "image/jpeg" || file.raw.type === "image/png";
  const isLt1M = file.size / 1024 / 1024 < 1;

  if (!isJPGorPNG) {
    ElMessage.error("只能上传jpg或png格式");
    return fileList.value.pop();
  }
  if (!isLt1M) {
    ElMessage.error("图片大小不能超过1MB");
    return fileList.value.pop();
  }
};

const uploadBlogInfo = () => {
  if (blogTags.value.length == 0 || blogTags.value.length > 3) {
    return ElMessage.error("请选择1-3个文章标签");
  }
  publishBlogForm.value.tags = blogTags.value.join();
  //发送请求
  blogFormRule.value.validate((vali) => {
    if (!vali) {
      return ElMessage.error("格式错误");
    } else {
      ElMessageBox.confirm("确定发布文章吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        if (fileList.value.length != 0) {
          //上传图片
          const formData = new FormData();
          for (let i = 0; i < fileList.value.length; i++) {
            formData.append("file", fileList.value[i].raw);
          }
          const res1 = await proxy.$api.ApiUploadBlogImg(formData);
          publishBlogForm.value.media = res1;
        }
        //上传blog
        const res2 = await proxy.$api.ApiUploadBlog(publishBlogForm.value);
        ElMessage.success(res2);
        //刷新
        publishBlogVisable.value = false;
        location.reload();
      });
    }
  });
};
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
};
const handleRemove = (file) => {
  const index = fileList.value.indexOf(file);
  fileList.value.splice(index, 1);
};
const searchTop = async () => {
  if (searchTopList.value == null) {
    const res = await proxy.$api.ApiGetSearchTop();
    searchTopList.value = res;
  }
  searchTopShow.value = true;
};
const selectSearchTopItem = (item) => {
  searchTopValue.value = item;
};
const toSearch = () => {
  blogSearch.value.pageNum = 1;
  blogSearch.value.searchParam = searchTopValue.value;
  loadingMoreDisabled.value = false;
  blogInfo.value = [];
  if (blogSearch.value.type != 4) {
    getBlogInfo();
  } else if (blogSearch.value.type == 4) {
    getMyFocusBlogInfo();
  }
  //发送请求
};
</script>



<style lang="scss" scoped>
.header {
  z-index: 200;
}
.bg {
  width: 100%;
  height: 248px;
  img {
    width: 100%;
    height: 100%;
  }
}
.searchArea {
  margin: 0 50%;
  margin-top: -100px;
  width: 340px;
  display: flex;
  justify-content: space-around;

  transform: translate(-50%, 0%);
  :deep(.el-input__wrapper) {
    border-radius: 50px;
  }
  z-index: 100;
}
.searchAreaTop {
  display: block;
}
.dropdown-list {
  background-color: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  margin-top: 5px;
  min-height: 50px;
  max-height: 185px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  z-index: 100;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 和 Edge */
}

/* 隐藏 Webkit 浏览器（Chrome, Safari）的滚动条 */
.dropdown-list::-webkit-scrollbar {
  display: none;
}
.dropdown-list li {
  padding: 8px 12px;
  cursor: pointer;
  max-width: 240px; /* 设置最大宽度 */
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 超出部分用省略号表示 */
}

.dropdown-list li:hover {
  background-color: #e3e8ef;
}
.content {
  display: flex;
  width: 100%;
  .el-card {
    border-radius: 4px;
    margin: 15px 0;
  }

  .left-card {
    position: fixed;
    margin-left: 50px;
    .goPagebtn {
      margin: 20px 0;
      width: 200px;
      border-radius: 17px;
      border: 3px solid rgb(154, 204, 251);
    }
    .tologinbtn {
      margin: 20px 0;
      width: 200px;
      border-radius: 17px;
    }
    .interact {
      cursor: pointer;
      align-content: space-around;
      display: flex;
      justify-content: space-around;
      div {
        padding: 0 20px;
      }
      .iconfont {
        font-size: 30px;
      }
      .interact-name {
        font-size: 20px;
      }
      .interact-num {
        color: red;
        font-size: 20px;
        font-weight: 600;
      }
      span {
        margin: 10px 0;
        display: block;
      }
    }
  }
  .main-content {
    width: 50%;
    .disloading {
      width: 100%;
      height: 50px;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
      margin: -15px 0 0 10px;
    }
    margin-left: 400px;
    .middle-card {
      margin-left: 10px;
      min-width: 600px;
    }
    .work-card {
      padding: 10px;
    }
    .classify {
      position: relative;
      :deep(.el-menu--horizontal) {
        width: 450px;
        display: flex;
        justify-content: space-around;
      }
      .publish {
        position: absolute;
        right: 50px;
        top: 30px;
      }
    }
  }

  .right-card {
    margin-left: 10px;
    width: 25%;
    h2 {
      margin-bottom: 30px;
    }
    .el-timeline {
      font-size: 20px;
    }
  }
}
</style>
