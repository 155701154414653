<template>
<div class="bgdiv">
    <Header :avatorImg="store.introInfo.avator" />
    <div class="containers">
        <div class="left">
             <el-menu
                :default-active="route.path"
                router
                background-color="transparent"
            >
            <el-menu-item index="/chat" >
                <el-button icon="ChatDotRound" type="primary" :plain="route.path!='/chat'"></el-button>
            </el-menu-item>
            <el-menu-item index="/chat/friend" >
                <el-button icon="User" type="primary" :plain="route.path!='/chat/friend'"></el-button>
            </el-menu-item>
            <el-menu-item index="/chat/search" >
                <el-button icon="ZoomIn" type="primary" :plain="route.path!='/chat/search'"></el-button>
            </el-menu-item>
            </el-menu>
        </div>
        <div class="right">
            <div class="rightTop"></div>
            <div class="chatListArea">
                <router-view @changeUser="updateCurrentChat"></router-view>
            </div>
        </div>
        <div class="main">
            <div class="mainTop">
                <span>{{currentChatUser.nickname}}</span>
            </div>
            <div class="chatArea">
                <div class="chatMainArea" ref="chatMainArea">
                    <ChatItem v-for="item,index in currentChatUser.chatRecord" :key="index" :chatRecord="item" :chatAvatar="currentChatUser.avatar"></ChatItem>
                </div>
            </div>
            <div class="chatInfoArea">
                <div class="emoArea">
                    <Emoji @select="addEmoji"></Emoji>
                </div>
                <div class="iptArea">
                    <el-input
                        v-model="msgInfo.content"
                        style="width: 100%;minHeight: 100%;"
                        :rows="2"
                        type="textarea"
                        placeholder="请输入聊天消息"
                        @keyup.enter="sendMsg"
                    />
                </div>
                    <el-button
                        class="sendMsg"
                        type="success"
                        text
                        bg
                        @click="sendMsg"
                    >
                        发送
                    </el-button>
            </div>
        </div>
    </div>
</div>
</template>
<script setup>
import Header from '@/components/Header.vue'
import { onBeforeMount, onMounted, ref,nextTick,defineProps,watch} from "vue";
import {useStore} from '@/pinia/index.js'
import ChatItem from '@/components/ChatItem.vue'
import {useRouter,useRoute} from 'vue-router'
import {getCurrentTimeFormatted,connectWs} from '@/util/func'
import { ElMessage } from 'element-plus';
import Emoji from '@/components/Emoji.vue'
const addEmoji = (emoji) => {
  msgInfo.value.content += emoji
}
const store = useStore()
let ws = null;
ws = connectWs(store,store.introInfo.id,"2")

onBeforeMount(() => {
    currentChatUser.value = store.chatUserList[0]
    msgInfo.value.recId = store.chatUserList[0].id
})
// 监听 chatRecord.type 的变化
watch(
  () => store.chatUserList[0],
    (newType, oldType) => {
    if (newType !== oldType) {
      currentChatUser.value = store.chatUserList[0]
      msgInfo.value.recId = store.chatUserList[0].id
    }
  }
);
const route = useRoute()
const router = useRouter()
const currentChatUser = ref({
                    id:'000000',
                    nickname:'AI',
                    avatar:'http://192.168.238.134:9000/mychat-miniprogram/user/avatar/000000.png',
                    chatRecord:[
                    ]
})
const msgInfo = ref({
    sendId:'',
    recId:'0',
    type:0,
    content:''
})
const chatListItem = ref(null)
const chatMainArea = ref(null)
const tag = ref(null)
const updateCurrentChat = (item)=>{
    currentChatUser.value = item
    msgInfo.value.recId = currentChatUser.value.id
}

onMounted(() => {
    store.unreadCount = 0
    if(store.introInfo.id == ''){
        router.push('/login')
    }
    chatMainArea.value.scrollTop = chatMainArea.value.scrollHeight-chatMainArea.value.offsetHeight
})


const sendMsg = ()=>{
    if(msgInfo.value.recId=="000000"){
        return ElMessage.error('机器人聊天功能开发中')
    }
    if(msgInfo.value.content.trim() == ""){
        return ElMessage.error("不能为空")
    }
    const tmp =  JSON.parse(JSON.stringify(msgInfo.value))
    tmp.sendId = store.introInfo.id
    tmp.time = getCurrentTimeFormatted()
    ws.send(JSON.stringify(tmp))
    store.chatUserList.find(a=>{
        if(a.id == currentChatUser.value.id){
            a.chatRecord.push(tmp)
            return true
        }
    })
    msgInfo.value.content = ''
    nextTick(()=>{
        chatMainArea.value.scrollTop = chatMainArea.value.scrollHeight-chatMainArea.value.offsetHeight
    })

}
</script>
<style lang="scss" scoped>
.bgdiv{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-image: url('@/assets/bg.jpg');
    background-size: cover;
    .containers{
        position: absolute;
        display: flex;
        flex-wrap: nowrap;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-45% );
        width: 80%;
        height: 85vh;
        border-radius: 50px;
        box-shadow: 5px 5px 10px gray;
        .left{
            display: inline-block;
            padding: 150px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            width: 110px;
            background-color: rgb(207, 150, 240);
            overflow: hidden;
            border-radius: 50px 0 0 50px;
            :deep(.el-menu-item){
                border-radius: 50%;
                width: 0;
                height: 0;
                margin-bottom: 80px;
            }
            .el-button{
                width: 50px;
                border-radius: 50%;
                height: 50px;
            }
        }
        .right::before{
            content: '';
            border: 1px 0 solid rgb(223, 219, 219);
            display: block;
            height: 20px; /* 百分比 */
            width: 100%;
            background-color: rgb(245, 241, 241); 
        }
        .right{
            display: inline-block;
            height: 100%;
            width: 300px;
            .chatListArea{
                width: 100%;
                border-right: 1px solid rgb(214, 214, 214);
                overflow: scroll;
                height: calc(100% - 20px);
            }
            .chatListArea::-webkit-scrollbar{
                display: none;
            }
        }
        .main{
            height: 100%;
            width: 100%;
            border-radius: 0 50px 50px 0;
            div{
                width: 100%;
            }
            .mainTop{
                background-color: rgb(245, 245, 245);
                border-bottom: 1px solid rgb(231, 231, 231);
                height: 10%;
                text-align: center;
                line-height: 50px;
                font-weight: 700;
                font-size: 25px;
            }
            .chatArea{
                background-color: rgb(245, 245, 245);
                height: 65%;
                padding: 0 60px;
                .chatMainArea{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: scroll;
                }
                .chatMainArea::-webkit-scrollbar{
                    display: none;
            }
            }
            .chatInfoArea{
                position: relative;
                border-top: 1px solid rgb(238, 238, 238);
                background-color: rgb(245, 245, 245);
                height: 25%;
                padding: 10px 20px;
                .emoArea{
                    // border: 1px solid red;
                    width: 100%;
                    height: 30px;
                }
                .iptArea{
                    // border: 1px solid red;
                    width: 100%;
                    height: 130px;
                    margin-top: 10px;
                    :deep(.el-textarea__inner){
                        font-size: 1.5em;
                        resize: none; 
                        height: 90px !important;
                    }
                }
                .sendMsg{
                    position: absolute;
                    right: 50px;
                    bottom: 60px;
                    font-size: 30px;
                    padding: 10px;
                    height: 55px;
                }
            }
        }
    }
}
</style>

